import { useState, useEffect } from "react";
import LeftArrow from "@material-ui/icons/ArrowBack";
import TextFieldSearch from "../../components/utility/TextFieldSearch";
import ChirurgiaIcon from "../../images/categoryIcon/icon_category_chirurgiaguidata.png";
import ProtesifisIcon from "../../images/categoryIcon/icon_category_protesifissa.png";
import ProtesimobIcon from "../../images/categoryIcon/icon_category_protesimobile.png";
import ToothIcon from "../../images/categoryIcon/icon_category_variecadcam.png";
import OrtodonziaIcon from "../../images/categoryIcon/icon_category_ortodonzia.png";
import { CategoryChip } from "../../components/collection/CategoryChip";
import { LavorazioneCell } from "../../components/lavorazioni/LavorazioneCell";
import { FooterStep } from "../../components/ordini/FooterStep";
import ExitOrderModal from "../../components/ordini/ExitOrderModal";
import { CustomButton } from "../../components/utility/CustomButton";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Email";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import LoaderCustom from "../../components/utility/LoaderCustom";
import { SERVICE_PATH } from "../../App";


export function Step2View(props){

    const { state } = useLocation();
    const navigate = useNavigate();

    const [lavorazioniFilter, setLavorazioniFilter] = useState("");
    const [categorySelected, setCategorySelected] = useState("tutte")
    const [lavorazioni, setLavorazioni] = useState([]);
    const [lavorazioniOriginali, setLavorazioniOriginali] = useState([]);
    const [flag, setFlag] = useState(false);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [paziente, setPaziente] = useState(state.paziente);
    const [carrello, setCarrello] = useState(null);
    

    useEffect(() => {

        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/step2",{
                replace: false,
                state: {
                    paziente: paziente
                }
            })
            
        }, false);

        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        }  else {
            getLavorazioni()
        }
    }, []);

    const CATEGORIES = [
        {
          id: 'chirurgia guidata',
          label: 'Chirurgia \nguidata',
          image: ChirurgiaIcon,
        },
        {
          id: 'protesi fissa',
          label: 'Protesi \nfissa',
          image: ProtesifisIcon,
        },
        {
          id: 'protesi mobile',
          label: 'Protesi \nmobile',
          image: ProtesimobIcon,
        },
        {
          id: 'varie cad cam',
          label: 'Varie \nCAD CAM',
          image: ToothIcon,
        },
        {
          id: 'ortodonzia',
          label: 'Ortodonzia',
          image: OrtodonziaIcon,
        },
    ];

    function changeCategory(category){
        setLavorazioniFilter("");
        if(categorySelected === category.id){
            setCategorySelected("tutte");
            setLavorazioni(lavorazioniOriginali);
        } else {
            setCategorySelected(category.id);
            setLavorazioni(lavorazioniOriginali.filter(lavorazione => lavorazione.ds_category.toUpperCase() === category.id.toUpperCase()))
        }
    }

    function goNext(){
        if(flag){
            navigate("/step3",{
                replace: false
            })
        }
       
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    function handleLavorazione(lavorazione){
        if(checkLavorazioneInCart(lavorazione)){
            handleOpen();
        } else {
            navigate("/lavorazioneDetail",{
                replace: false,
                state: {
                    lavorazione: lavorazione
                }
            })
        }
    }

    function checkLavorazioneInCart(lavorazione){
        return carrello.cart.find((elem) => (elem._id === lavorazione._id))
    }

    function getLavorazioni() {
        setIsLoading(true)
        const cart = localStorage.getItem("cart");
        if (cart) {
            const foundCart = JSON.parse(cart);
            
            if(foundCart.cart.length > 0){
                setFlag(true);
            }
            setCarrello(foundCart);
            
        }
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
          const foundUser = JSON.parse(loggedInUser);
          axios.get(SERVICE_PATH + "/orders/lavorazioni", {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Access-Control-Allow-Methods": "POST, GET, PUT",
                "Access-Control-Allow-Headers" : "Content-type",
                "Content-type": "Application/json",
                'Authorization' : `Bearer ${foundUser.token}`
            }
            }).then((response) => {
                if(response.data){
                    setLavorazioni(response.data);
                    setLavorazioniOriginali(response.data);
                    setIsLoading(false);
                }
            }).catch((error) => {
                console.error(error);
            })
          
        } 
        
    }

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                
                <div 
                    style={{
                        position:"fixed",
                        top:0,
                        zIndex:100,
                        width: "100%",
                        
                        backgroundColor: "#28242D",
                        borderBottomLeftRadius: "28px",
                        WebkitBorderBottomRightRadius: "28px",
                        boxShadow: "0 4px 2px -2px #28242D1A",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        
                    }}
                >
                    <div
                        onClick={() => navigate("/step1",{
                            replace: false
                        })}
                        style={{
                            display: "flex",
                            justifyContent:"start",
                            alignItems: "center",
                            marginLeft: "20%",
                            marginTop: "24px"
                        }}
                    >
                        <LeftArrow 
                            style={{
                                color: "#BFBDC1",
                                height: "28px",
                                width: "28px",
                                cursor: "pointer"
                            }}
                        />
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "14px",
                                color: "#BFBDC1",
                                textDecoration: "underline",
                                marginBottom: "0px",
                                marginLeft: "12px",
                                marginTop: "0px",
                                cursor: "pointer"
                            }}
                        >
                            Indietro
                        </p>
                        
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent:"left",
                            marginLeft: "20%",
                            marginRight: "20%"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "28px",
                                color: "#FFFFFF",
                                textAlign:"left",
                                marginBottom: "0px",
                                marginTop: "24px"
                            }}
                        >
                            Nuova prescrizione
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#BFBDC1",
                                textAlign:"left",
                                marginBottom: "28px",
                                marginTop: "4px"
                            }}
                        >
                            Seleziona le lavorazioni per&nbsp;
                            <span
                                style={{
                                    fontFamily: "Bold",
                                    fontSize: "16px",
                                    color: "#94CC00",
                                    
                                }}
                            >
                                {paziente.nome_cognome}
                            </span>
                        </p>
                    </div>
                </div>
                
            </div>
            <div
                style={{
                    marginTop: "200px"
                }}
            >
                <TextFieldSearch 
                    id="search"
                    className="textfield-md"
                    placeholder="Cerca lavorazione..."
                    value={lavorazioniFilter}
                    onChange={(id, value) => {
                        setLavorazioniFilter(value);
                        if(categorySelected === "tutte"){
                            setLavorazioni(lavorazioniOriginali.filter(lavorazione => lavorazione.ds_name.toUpperCase().includes(value.toUpperCase())));
                        } else {
                            setLavorazioni(lavorazioniOriginali.filter(lavorazione => lavorazione.ds_name.toUpperCase().includes(value.toUpperCase()) && lavorazione.ds_category.toUpperCase() === categorySelected.toUpperCase()));
                        }
                        
                    }}
                />
            </div>
            <div
                className="hide-scroll"
                style={{
                    marginTop: "24px",
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    padding: "2px"
                }}
            >
                {CATEGORIES.map((el,index) => (
                    <CategoryChip key={el.id} setCategory={changeCategory} isSelected={categorySelected === el.id} category={el}/>
                ))}
            </div>
            {isLoading ? (
                <div
                    style={{
                    display:"flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "52px",
                    width: "100%",
                    height: "100%",
                    zIndex: "1000"
                    }}
                >
                    <LoaderCustom
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%"
                        }}
                    />
                </div>
            ) : lavorazioni.length === 0 ? (
                <div 
                    style={{
                        display:"flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "52px"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "20px",
                            color: "#6E6B77",
                            textAlign:"center",
                            marginBottom: "0px",
                            
                        }}
                    >
                        Nessun riscontro...
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#BFBDC1",
                            textAlign:"center",
                            marginBottom: "0px",
                            
                        }}
                    >
                        Non abbiamo trovato lavorazioni che corrispondono alla tua ricerca.
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#BFBDC1",
                            textAlign:"center",
                            marginBottom: "0px",
                            marginTop: "40px"
                        }}
                    >
                        Per&nbsp;
                        <span
                            style={{
                                fontFamily:"Medium",
                                color: "#6E6B77"
                            }}
                        >
                            Consulenze
                        </span>
                        &nbsp;e&nbsp;
                        <span
                            style={{
                                fontFamily:"Medium",
                                color: "#6E6B77"
                            }}
                        >
                            Ortodonzia
                        </span>
                        &nbsp;contatta il nostro specialist e richiedi un preventivo
                    </p>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "24px"
                        }}
                    >
                        <PhoneIcon 
                            style={{
                                color: "#6E6B77",
                                height: "20px",
                                width: "20px",
                                marginRight: "12px"
                            }}
                        />
                        <p
                        
                            style={{
                                fontFamily:"Medium",
                                fontSize: "16px",
                                color: "#6E6B77",
                                textDecoration: "underline",
                                marginRight: "40px"
                            }}>
                            Chiama
                        </p>
                        <MailIcon 
                            style={{
                                color: "#6E6B77",
                                height: "20px",
                                width: "20px",
                                marginRight: "12px"
                            }}
                        />
                        <p
                        
                            style={{
                                fontFamily:"Medium",
                                fontSize: "16px",
                                color: "#6E6B77",
                                textDecoration: "underline"
                            }}>
                            Scrivici
                        </p>

                    </div>

                </div>
            ) : (
                <div
                    style={{marginTop:"32px", marginBottom: "180px"}}
                >
                    {lavorazioni.map((el,index) => (
                        <LavorazioneCell key={el._id_lavorazione} lavorazione={el} handleLavorazione={handleLavorazione} isInCart={checkLavorazioneInCart(el)}/>
                    ))}
                </div>
            )}
            <FooterStep step="2" text={"Chiudi prescrizione"} flag={flag} isCart={true} handleClick={goNext}/>
            <ExitOrderModal isOpen={open} onClose={handleClose}>
                <>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"20px",
                            color: "#94CC00",
                            textAlign: "center !important",
                            marginTop: "0px"
                        }}
                    >
                        Già in prescrizione!
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#6E6B77",
                            textAlign:"center",
                            marginTop: "8px",
                            marginBottom:"0px"
                        }}
                    >
                        Questa lavorazione è già stata aggiunta al carrello.
                    </p>
                    
                    
                    <div
                        onClick={handleClose}
                        style={{
                            textAlign: "center",
                            marginTop: "30px",
                            width: "100%"
                        }}
                    >
                        <label
                            style={{
                                fontFamily: "Medium",
                                fontSize: "16px",
                                color: "#BFBDC1",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                        >
                            CHIUDI
                        </label>
                    </div>
                </>
            </ExitOrderModal>
        </div>
    )
}