export function PersonalDataRow(props){
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "8px",
                marginBottom: "0px"
            }}
        >
            <p
                style={{
                    fontFamily: "Medium",
                    fontSize: "12px",
                    color: "#BFBDC1",
                    textAlign:"left",
                    margin:"0px",
                    
                }}
            >
                {props.myKey}
            </p>
            <p
                style={{
                    fontFamily: "Regular",
                    fontSize: "16px",
                    color: "#28242D",
                    textAlign:"right",
                    margin:"0px",
                    
                }}
            >
                {props.value}
            </p>
        </div>
    )
}