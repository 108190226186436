import { useState } from "react";
import CheckIcon from "@material-ui/icons/CheckBox";
import CheckBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

export function NumItem(props){

    function handleNum(){
        setNumSelected(!numSelected)
        props.handleNum(props.currentNum);
    }

    const [numSelected, setNumSelected] = useState(props.numerazioni.includes(props.currentNum));

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: `${numSelected ? "#94CC00" : "#FFFFFF"}`,
                cursor: "pointer",
                border: `${numSelected ? "1px solid #94CC00" : "1px solid #E9EBE7"}`,
                marginTop: "8px"
            }}
            onClick={handleNum}
        >
            {numSelected ? (
                    <CheckIcon 
                        style={{
                            width: "24px",
                            height: "24px",
                            color: "#FFFFFF",
                            marginLeft: "16px"
                        }}
                    />
                ) : (
                    <CheckBlankIcon 
                        style={{
                            width: "24px",
                            height: "24px",
                            color: "#BFBDC1",
                            marginLeft: "16px"
                        }}
                    />
                )
            }
            
            <p
                style={{
                    fontFamily: "Regular",
                    fontSize: "16px",
                    color: `${numSelected ? "#FFFFFF" : "#BFBDC1"}`,
                    marginLeft: "16px"
                }}
            >
                {props.currentNum}
            </p>

        </div>
    )
}