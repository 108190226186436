import { useState, useEffect } from "react";
import LogoTopBar from "../../images/logo_topbar.png";
import UserLogo from "@material-ui/icons/Person";
import TextFieldSearch from "../../components/utility/TextFieldSearch";
import AddIcon from "@material-ui/icons/Add";
import { StatusChip } from "../../components/collection/StatusChip";
import BlockIcon from "../../images/statusIcon/block.svg";
import ListBulletIcon from "../../images/statusIcon/format_list_bulleted.svg";
import InventoryIcon from "../../images/statusIcon/inventory.svg";
import PauseIcon from "../../images/statusIcon/pause.svg";
import ScheduleIcon from "../../images/statusIcon/schedule.svg";
import TaskAltIcon from "../../images/statusIcon/task_alt.svg";
import { OrderCell } from "../../components/ordini/orderCell";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LoaderCustom from "../../components/utility/LoaderCustom";
import axios from "axios";
import { SERVICE_PATH } from "../../App";

export function HomeView(props){

    const [orderFilter, setOrderFilter] = useState("");
    const [statusSelected, setStatusSelected] = useState(0);
    const [ordini, setOrdini] = useState([]);
    const [ordiniOriginali, setOrdiniOriginali] = useState([]);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {

        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/",{
                replace: false
            })
            
        }, false);

        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
          const foundUser = JSON.parse(loggedInUser);
          setUser(foundUser);
          getOrders();
        } else {
            navigate("/signin",{
                replace: true
            })
          
        }
    }, []);

    const CHIPS = [
        {
          label: 'TUTTI',
          value: 0,
          icon: ListBulletIcon,
          color: '#6E6B77',
        },
        {
          label: 'IN ATTESA',
          value: 100,
          icon: ScheduleIcon,
          color: '#F7C336',
        },
        {
          label: 'CONFERMATI',
          value: 200,
          icon: TaskAltIcon,
          color: '#94CC00',
        },
        {
          label: 'CONSEGNATI',
          value: 300,
          icon: InventoryIcon,
          color: '#28242D',
        },
        {
          label: 'RIFIUTATI',
          value: 500,
          icon: BlockIcon,
          color: '#ff2f5f',
        },
        {
          label: 'IN SOSPESO',
          value: 400,
          icon: PauseIcon,
          color: '#bfbdc1',
        },
    ];

    function changeStatus(status){
        
        setStatusSelected(status.value);
        setOrderFilter("");
        if(status.value === 0){
            setOrdini(ordiniOriginali);
        } else {
            setOrdini(ordiniOriginali.filter(ordine => parseInt(ordine.cd_status) === status.value));
        }
        
    }

    function handleProfile(){
        navigate("/profile",{
            replace: false
        })
    }

    function newOrder(){
        navigate("/step1", {
            replace: false,
        })
    }

    function getOrders(){
        setIsLoading(true)
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
          const foundUser = JSON.parse(loggedInUser);
          axios.get(SERVICE_PATH + "/orders", {
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Access-Control-Allow-Methods": "POST, GET, PUT",
                "Access-Control-Allow-Headers" : "Content-type",
                "Content-type": "Application/json",
                'Authorization' : `Bearer ${foundUser.token}`
            }
            }).then((response) => {
                if(response.data){
                    
                    setOrdini(response.data);
                    setOrdiniOriginali(response.data);
                    setIsLoading(false);
                }
            }).catch((error) => {
                console.error(error);
            })
          
        } 
    }

    function handleOrdine(ordine){
        navigate("/orderDetail", {
            replace: false,
            state: {
                ordine: ordine
            }
        })
    }

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                <div 
                    style={{
                        position:"fixed",
                        top:0,
                        
                        width: "100%",
                        height: "80px",
                        backgroundColor: "white",
                        borderBottomLeftRadius: "28px",
                        WebkitBorderBottomRightRadius: "28px",
                        boxShadow: "0 4px 2px -2px #28242D1A",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    
                    <img
                        src={LogoTopBar}
                        alt=''
                        style={{
                            height: "40px",
                            display: "inline-block",
                            marginLeft: "20%"
                        }}
                    />
                    <div
                        onClick={handleProfile}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: "20%",
                            alignItems: "center"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#6E6B77",
                                textDecoration: "underline",
                                marginBottom: "0px",
                                marginRight: "8px",
                                marginTop: "0px",
                                cursor: "pointer"
                            }}
                        >
                            Profilo
                        </p>
                        <UserLogo 
                            
                            style={{
                                color: "#6E6B77",
                                height: "28px",
                                width: "28px",
                                marginRight: "20%",
                                cursor: "pointer"
                            }}
                        />
                    </div>
                </div>
               
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:"flex-end",
                    width: "100%",
                    marginTop: "100px",
                    marginBottom: "28px"
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "28px",
                        color: "#28242D",
                        textAlign:"center",
                        marginBottom: "0px",
                        
                    }}
                >
                    Le tue prescrizioni
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        textAlign:"center",
                        marginTop: "8px",
                        marginBottom:"0px"
                    }}
                >
                    {ordiniOriginali.length === 1 ? ordiniOriginali.length + " prescrizione effettuata" : ordiniOriginali.length + " prescrizioni effettuate"}
                </p>
            </div>
            <div>
                <TextFieldSearch 
                    id="search"
                    className="textfield-md"
                    placeholder="Cerca prescrizione..."
                    value={orderFilter}
                    onChange={(id, value) => {
                        setOrderFilter(value);
                        if(statusSelected === 0){
                            setOrdini(ordiniOriginali.filter(ordine => ordine.paziente.nome_cognome.toUpperCase().includes(value.toUpperCase()) || ordine.order_code.toUpperCase().includes(value.toUpperCase()))); 
                        } else {
                            setOrdini(ordiniOriginali.filter(ordine => (ordine.paziente.nome_cognome.toUpperCase().includes(value.toUpperCase()) || ordine.order_code.toUpperCase().includes(value.toUpperCase())) && parseInt(ordine.cd_status) === statusSelected));
                        }
                    }}
                />
            </div>
            <div
                className="hide-scroll"
                style={{
                    marginTop: "24px",
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    padding: "0px"
                }}
            >
                {CHIPS.map((el,index) => (
                    <StatusChip key={el.value} setStatus={changeStatus} isSelected={statusSelected === el.value} status={el}/>
                ))}

            </div>

            {isLoading ? (
                <div
                    style={{
                    display:"flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "52px",
                    width: "100%",
                    height: "100%",
                    zIndex: "1000"
                    }}
                >
                    <LoaderCustom
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%"
                        }}
                    />
                </div>
            ) : ordini.length === 0 ? (
                <div 
                    style={{
                        display:"flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "20px",
                            color: "#6E6B77",
                            textAlign:"center",
                            marginBottom: "0px",
                            
                        }}
                    >
                        Nessuna prescrizione in elenco
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#BFBDC1",
                            textAlign:"center",
                            marginBottom: "0px",
                            
                        }}
                    >
                        Non hai ancora creato nessuna prescrizione,
                    </p>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "16px",
                            color: "#BFBDC1",
                            textAlign:"center",
                            marginBottom: "0px",
                            marginTop: "0px"
                        }}
                    >
                        clicca sull'icona + per iniziare.
                    </p>
                </div>
            ) : (
                <div
                    style={{
                        marginTop: "40px",
                        marginBottom: "200px"
                    }}
                >
                    {ordini.map((el,index) => (
                        <OrderCell ordine={el} handleOrder={handleOrdine} />
                    ))}
                </div>
                
            )}



            <div
                className="myButton"
                onClick={newOrder}
                style={{
                    position: "fixed",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#94CC00",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "56px",
                    borderRadius: "28px",
                    bottom: "64px",
                    right: "20%",
                    padding: "0px 24px 0px 20px",
                   
                    cursor: "pointer"
                }}
            >
                <AddIcon 
                    style={{
                        color: "#FFFFFF",
                        height: "28px",
                        width: "28px",
                        marginRight: "8px",
                        
                    }}
                />
                <label
                    style={{
                        fontFamily:"Bold",
                        fontSize:"16px",
                        color:"white",
                        cursor: "pointer"
                    }}
                >
                    NUOVO
                </label>

            </div>
        </div>
    )
}