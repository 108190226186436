export function CustomButton(props){

    function handleClick() {
        props.handleAction();
    }

    return (
        <div
            className={props.isPrivacy && !props.flag ? "" : "myButton"}
            onClick={handleClick}
            style={{
                backgroundColor: `${props.myColor}`,
                height: "52px",
                fontFamily:"Bold",
                fontSize: "16px",
                color: "#FFFFFF",
                borderRadius: "26px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                marginRight: "40px",
                marginLeft: "40px",
            }}
        >
            
                {props.titleTxt}
           
        </div>
    )
}