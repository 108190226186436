import CloseIcon from "@material-ui/icons/Close";
import SupportIcon from "@material-ui/icons/ContactSupport";
import ProfileIcon from "@material-ui/icons/Person";
import LogoutIcon from "../../images/logout.svg";
import { ProfileCell } from "../../components/profile/ProfileCell";
import LogoProfilo from "../../images/logo_profilo.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";


export function ProfileView(props){

    const navigate = useNavigate();

    useEffect(() => {
        
        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/profile",{
                replace: false
            })
            
        }, false);

        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        } 
    }, []);

    function goToPersonalData(){
        navigate("/profile/personalData",{
            replace: false
        })
    }

    function goToSupport(){
        navigate("/profile/support",{
            replace: false
        })
    }

    function handleLogout(){
        localStorage.clear();
        navigate("/signin",{
            replace: true
        })
    }

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "40px",
                    
                }}
            >
                <div
                    onClick={() => navigate("/",{
                        replace: false
                    })}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        
                        alignItems: "center"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "14px",
                            color: "#6E6B77",
                            textDecoration: "underline",
                            marginBottom: "0px",
                            marginRight: "12px",
                            marginTop: "0px",
                            cursor: "pointer"
                        }}
                    >
                        Torna alla home
                    </p>
                    <div
                        style={{
                            height: "36px",
                            width: "36px",
                            backgroundColor: "#E9EBE7",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "18px",
                            cursor: "pointer"
                        }}
                    >
                        <CloseIcon 
                            
                            style={{
                                color: "#6E6B77",
                                height: "24px",
                                width: "24px",
                            }}
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left"
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "28px",
                        color: "#28242D",
                        
                        marginBottom: "0px",
                        marginTop: "60px"
                    }}
                >
                    Ciao!
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        
                        marginTop: "8px",
                        marginBottom:"0px"
                    }}
                >
                    Ti diamo il benvenuto nella Dentalmax Care. Qui sotto trovi tutte le informazioni inerenti al tuo account.
                </p>
            </div>

            <div
                style={{
                    marginTop: "34px"
                }}
            >
                <ProfileCell 
                    handleAction={goToPersonalData}
                    icon={<ProfileIcon 
                        style={{
                            color: "#94CC00",
                            height: "24px",
                            width: "24px",
                            marginRight: "12px",
                            
                        }}
                    />}
                    mainTitle="Dati personali"
                    subtitle="Modifica i dati del tuo profilo."
                
                />
                <ProfileCell 
                    handleAction={goToSupport}
                    icon={<SupportIcon 
                        style={{
                            color: "#94CC00",
                            height: "24px",
                            width: "24px",
                            marginRight: "12px",
                            
                        }}
                    />}
                    mainTitle="Supporto clienti"
                    subtitle="Hai bisogno di aiuto? Contattaci!"
                
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "120px"
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        textAlign: "center",
                        marginTop: "8px",
                        marginBottom:"0px"
                    }}
                >
                    Dentalmax, laboratorio odontotecnico è un marchio di Maste srl
                </p>
                <div
                    style={{
                        textAlign: "center"
                    }}
                >
                    <img
                        src={LogoProfilo}
                        alt=''
                        style={{
                            height: "40px",
                            width: "169px",
                            display: "inline-block",
                            marginTop:"20px",
                        }}
                    />
                    
                </div>
                <div
                    style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems: "center",
                        marginTop:"60px"
                    }}
                >
                    <div
                        className="myButton"
                        onClick={handleLogout}
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#28242D",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "56px",
                            borderRadius: "28px",
                            
                            padding: "0px 24px 0px 20px",
                        
                            cursor: "pointer"
                        }}
                    >
                        <img
                            src={LogoutIcon}
                            alt=''
                            style={{
                                height: "24px",
                                width: "24px",
                                display: "inline-block",
                                marginRight:"8px",
                                filter: "invert(100%) sepia(9%) saturate(7500%) hue-rotate(264deg) brightness(112%) contrast(113%)"
                            }}
                        />
                        
                        <label
                            style={{
                                fontFamily:"Bold",
                                fontSize:"16px",
                                color:"white",
                                cursor: "pointer"
                            }}
                        >
                            LOGOUT
                        </label>

                    </div>
                </div>
            </div>
            
        </div>
    )
}