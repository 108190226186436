import CloseIcon from "@material-ui/icons/Close";
import { formatPrice } from "../../App";

export function LavorazioneCartCell(props){

    function deleteLavorazione(){
        props.onDelete(props.lavorazione)
    }

    return (
        <div
            style={{
                display:"flex",
                flexDirection: "column",
                justifyContent:"space-between",
                
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "start"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "10px",
                            color: "#BFBDC1",
                            
                            marginBottom: "0px",
                            marginTop: "16px"
                        }}
                    >
                        {props.lavorazione.ds_category.toUpperCase()}
                    </p>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "16px",
                            color: "#28242D",
                            
                            marginBottom: "0px",
                            marginTop: "2px"
                        }}
                    >
                        {props.lavorazione.ds_name}
                    </p>
                </div>
                {props.isDetail ? (
                    ""
                ) : (
                    <div
                        onClick={deleteLavorazione}
                        style={{
                            cursor:"pointer"
                        }}
                    >
                        <CloseIcon 
                            style={{
                                color: "#BFBDC1",
                                height: "24px",
                                width: "24px",
                                marginTop: "20px",
                            }}
                        />
                    </div>
                )}
                
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "end",
                    marginTop: "4px"
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "12px",
                        color: "#6E6B77",
                        
                        margin: "0px",
                        
                    }}
                >
                    {props.lavorazione.variante !== "" ? props.lavorazione.arcata + "" + props.lavorazione.numerazione + " - " + props.lavorazione.variante + " (" + props.lavorazione.ds_note_colorazione + ")" : props.lavorazione.ds_note_colorazione !== "" ? props.lavorazione.arcata + " (" + props.lavorazione.ds_note_colorazione + ") " + props.lavorazione.numerazione : props.lavorazione.arcata + "" + props.lavorazione.numerazione }
                    
                </p>
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "16px",
                        color: "#28242D",
                        
                        margin: "0px",
                        
                    }}
                >
                    € {formatPrice(props.lavorazione.prezzo)}
                </p>
            </div>
            <div 
                style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor:"#BFBDC1",
                    marginTop: "20px"
                }}
            />
        </div>
    )
}