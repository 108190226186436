export function CategoryChip(props){

    function setCategory(){
        props.setCategory(props.category);
    }

    return (
        <div
            onClick={setCategory}
            style={{
                display: "flex",
                flexDirection: "column",
                height: "90px",
                width: "80px",
                minWidth: "80px",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 8px",
                backgroundColor: `${props.isSelected ? "#28242D" : "#FFFFFF"}`,
                cursor: "pointer",
                borderRadius: "16px",
                marginRight: "12px",
                boxShadow: "0 4px 2px -2px #28242D1A",
            }}
        >
            <img
                src={props.category.image}
                alt=''
                style={{
                    height: "32px",
                    width: "32px",
                    display: "inline-block",
                    marginBottom:"4px",
                    
                    filter: `${props.isSelected ? "grayscale(100%)" : "invert(82%) sepia(6%) saturate(107%) hue-rotate(227deg) brightness(93%) contrast(89%"}`
                }}
            />
            <label
                style={{
                    fontFamily: "Regular",
                    fontSize: "12px",
                    color: `${props.isSelected ? "#FFFFFF" : "#6E6B77"}`,
                    cursor: "pointer",
                    textAlign: "center"
                }}
            >
                {props.category.label}
            </label>

        </div>
    )
}