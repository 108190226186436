import * as moment from "moment"
import { formatPrice } from "../../App";

export function OrderCell(props){

    function getColorStatus(){
        switch (props.ordine.cd_status) {
            case "100":
                return "#F7C336";
            case "200":
                return "#94CC00";
            case "300":
                return "#28242D";
            case "400":
                return "#bfbdc1";
            case "500":
                return "#ff2f5f";
            default:
                return "white"
        }
    }

    const statusColor = getColorStatus();

    function handleOrder(){
        props.handleOrder(props.ordine);
    }

    return (
        <div
            onClick={handleOrder}
            style={{
                backgroundColor:"white",
                borderRadius: "16px",
                boxShadow: "0 4px 2px -2px #28242D1A",
                padding: "16px 24px 16px 24px",
                margin: "12px 0px",
                cursor: "pointer"
            }}
        >
            <div 
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "20px",
                        color: "#28242D",
                        marginBottom: "0px",
                        marginTop: "0px"
                    }}
                >
                    {props.ordine.paziente.nome_cognome}
                </p>
                <div 
                    style={{
                        width:"12px",
                        height:"12px",
                        borderRadius: "6px",
                        backgroundColor: `${statusColor}`
                    }}
                />

            </div>
            <div 
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center"
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "12px",
                        color: "#6D6A75",
                        marginBottom: "0px",
                        marginTop: "4px"
                    }}
                >
                    # {props.ordine.order_code} | {moment(props.ordine.createdAt).format("DD/MM/YYYY, HH:mm")}
                </p>
            </div>
            <div 
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "12px",
                        color: "#6D6A75",
                        marginBottom: "0px",
                        marginTop: "0px"
                    }}
                >
                    {props.ordine.lavorazioni.length} lavorazioni
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "20px",
                        color: "#28242D",
                        marginBottom: "0px",
                        marginTop: "12px"
                    }}
                >
                    € {formatPrice(props.ordine.total)}
                </p>
            </div>
        </div>
    )
}