import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import VisibilityLogo from "@material-ui/icons/Visibility";
import VisibilityOffLogo from "@material-ui/icons/VisibilityOff";

const Container = styled(Form.Group)`
	display: flex;
	justify-content: normal;
	align-items: center;
    height: 48px;
	background-color: white;
	border: 2px solid #E9EBE7;
	border-radius: 8px;

	padding: 2px;

	cursor: text;
`;

const Input = styled(Form.Control)`
	background: white;
	border: none;
    height: 48px;
    width: 100%;
    font: Regular;
    font-size: 16px;
    color: #28242D;
	&:focus {
		box-shadow: none;
        border:none;
	}
`;

const Icon = styled.div`
	padding-left: 8px;

	color: ${(props) => (props.iconColor ? props.iconColor : "#BFBDC1")};
`;



function TextFieldWithIcon(props) {

	const inputRef = useRef();
	const [searching, setSearching] = useState(false);
	const [inputTypeStr, setInputTypeStr] = useState(props.isPassword ? "password" : "text");


	return (
		<Container className={props.className} style={{ ...props.style }}>
            <Icon
				// onClick={() => {
				// 	if (searching) {
				// 		props.onChange(props.id, "");
				// 		setSearching(false);
				// 	} else {
				// 		inputRef.current.focus();
				// 	}
				// }}
			>
                {props.icon}
				{/* {searching ? <CloseIcon className="cursor-pointer" /> : <SearchIcon />} */}
			</Icon>
			<Input 
				type={inputTypeStr}
				placeholder={props.placeholder}
				value={props.value}
				onChange={(e) => {
					props.onChange(props.id, e.target.value);
					setSearching(e !== "");
				}}
			/>
			{props.isPassword ? (
				<Icon
					onClick={() => {
						if (inputTypeStr === "text") {
							setInputTypeStr("password");
						} else {
							setInputTypeStr("text");
						}
					}}
				>
					{inputTypeStr === "text" ? (
						<VisibilityLogo style={{color:"#BFBDC1", marginRight:"12px", cursor:"pointer"}}/>
					) :(
						<VisibilityOffLogo style={{color:"#BFBDC1", marginRight:"12px",cursor:"pointer"}}/>
					)}
					
					{/* {searching ? <CloseIcon className="cursor-pointer" /> : <SearchIcon />} */}
				</Icon>
			) : ""}
			
		</Container>
	);
}

TextFieldWithIcon.defaultProps = {
	placeholder: "Cerca prescrizioni...",
	value: "",
	onChange: (e) => {},
};

export default TextFieldWithIcon;