import RightArrow from "@material-ui/icons/ArrowForward";
import CartIcon from "../../images/shopping_cart.svg";
import { ArrowLeft, ArrowForward } from "@material-ui/icons";

export function FooterStep(props){

    function handleClick(){
        if(props.flag === true){
            props.handleClick();
        }
    }

    return (
        <div
            style={{
                position:"fixed",
                bottom:0,
                right: 0,
                height: "110px",
                width:"100%",
                backgroundColor: "white",
                borderTopLeftRadius: "28px",
                borderTopRightRadius: "28px",
                boxShadow:"0px -6px 6px -6px #28242D0D",
                zIndex:120,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:"space-between",
                    alignItems: "center",
                    marginLeft: "20%",
                    marginRight: "20%"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "12px",
                            color: "#BFBDC1",
                            marginBottom: "0px",
                            marginTop: "18px"
                        }}
                    >
                        Nuova prescrizione
                    </p>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "16px",
                            color: "#6E6B77",
                            marginBottom: "0px",
                            marginTop: "2px"
                        }}
                    >
                        Step {props.step}/3
                    </p>
                </div>
                {props.isCart ? (
                    <div
                        className={props.flag ? "myButton" : ""}
                        onClick={handleClick}
                        style={{
                            width: "180px",
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: `${props.flag ? "#28242D" : "#E9EBE7"}`,
                            justifyContent: "center",
                            alignItems: "center",
                            height: "52px",
                            borderRadius: "28px",
                            marginTop: "18px",
                            padding: "0px 20px 0px 20px",
                            cursor: "pointer"
                        }}
                    >
                        {/* <img 
                            src={CartIcon}
                            alt=''
                            style={{
                                color: "#FFFFFF",
                                height: "24px",
                                width: "24px",
                                
                            }}
                        /> */}
                        <label
                            style={{
                                fontFamily:"Bold",
                                fontSize:"16px",
                                color:"white",
                                cursor: "pointer",
                                
                            }}
                        >
                            {props.text}
                        </label>
                    </div>
                ) :(

                    <div
                        className={props.flag ? "myButton" : ""}
                        onClick={handleClick}
                        style={{
                            width: "180px",
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: `${props.flag ? "#28242D" : "#E9EBE7"}`,
                            justifyContent: "center",
                            alignItems: "center",
                            height: "52px",
                            borderRadius: "28px",
                            marginTop: "18px",
                            padding: "0px 24px 0px 20px",
                            cursor: "pointer"
                        }}
                    >
                        
                        <label
                            style={{
                                fontFamily:"Bold",
                                fontSize:"16px",
                                color:"white",
                                cursor: "pointer",
                                marginRight: "16px"
                            }}
                        >
                            {props.text}
                        </label>
                        <ArrowForward 
                           
                            style={{
                                color: "#FFFFFF",
                                height: "24px",
                                width: "24px",
                                
                            }}
                        />
                    </div>
                )}
            </div>
            
        </div>
    )
}