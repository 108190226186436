import LeftArrow from "@material-ui/icons/ArrowBack";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Email";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function SupportView(){

    const navigate = useNavigate();

    useEffect(() => {

        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/profile/support",{
                replace: false
            })
            
        }, false);

        
        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        } 
    }, []);

    function handleEmail(){
        window.open("mailto:areadigitale@dentalmax.eu");
    }

    function handlePhone(){
        window.open('tel:3476480095');
    }

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                <div 
                    style={{
                        position:"fixed",
                        top:0,
                        
                        width: "100%",
                        height: "80px",
                        backgroundColor: "white",
                        borderBottomLeftRadius: "28px",
                        WebkitBorderBottomRightRadius: "28px",
                        boxShadow: "0 4px 2px -2px #28242D1A",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <div
                        onClick={() => navigate("/profile",{
                            replace: false
                        })}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "20%",
                            alignItems: "center"
                        }}
                    >
                        <LeftArrow 
                            onClick={() => navigate("/profile",{
                                replace: false
                            })}
                            style={{
                                color: "#6E6B77",
                                height: "28px",
                                width: "28px",
                                marginLeft: "20%",
                                cursor: "pointer"
                            }}
                        />
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "14px",
                                color: "#6E6B77",
                                textDecoration: "underline",
                                marginBottom: "0px",
                                marginLeft: "12px",
                                marginTop: "0px",
                                cursor: "pointer"
                            }}
                        >
                            Indietro
                        </p>
                    </div>
                    
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "12px",
                            color: "#BFBDC1",
                            textAlign:"right",
                            marginBottom:"0px",
                            marginRight: "20%"
                        }}
                    >
                        SUPPORTO CLIENTI
                    </p>
                </div>
               
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    marginTop: "120px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "20px",
                        color: "#28242D",
                        
                        marginBottom: "0px",
                        marginTop: "32px"
                    }}
                >
                    Hai bisogno di aiuto?
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        
                        marginBottom: "0px",
                        marginTop: "12px"
                    }}
                >
                    Se hai bisogno di supporto per qualsiasi informazione puoi chiamare il nostro labboratorio al numero&nbsp; 
                    <span
                        style={{
                            color:"#94CC00",
                            fontFamily:"Medium"
                        }}
                    >
                        011 4222925 / 389 2078710
                    </span>
                    &nbsp; oppure scrivere una mail a&nbsp; 
                    <span
                        style={{
                            color:"#94CC00",
                            fontFamily:"Medium"
                        }}
                    >
                        areadigitale@dentalmax.eu
                    </span>
                </p>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    alignItems: "center",
                    
                }}
            >
                <div
                    className="myButton"
                    onClick={handlePhone}
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#94CC00",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "56px",
                        borderRadius: "28px",
                        padding: "0px 24px 0px 20px",
                        marginTop: "52px",
                        cursor: "pointer"
                    }}
                >
                    <PhoneIcon 
                        style={{
                            color: "#FFFFFF",
                            height: "20px",
                            width: "20px",
                            marginRight: "12px",
                            
                        }}
                    />
                    
                    <label
                        style={{
                            fontFamily:"Bold",
                            fontSize:"16px",
                            color:"white",
                            cursor: "pointer"
                        }}
                    >
                        CHIAMA ORA
                    </label>

                </div>
                <div
                    className="myButton"
                    onClick={handleEmail}
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#28242D",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "56px",
                        borderRadius: "28px",
                        padding: "0px 24px 0px 20px",
                        marginTop: "20px",
                        cursor: "pointer"
                    }}
                >
                    <MailIcon 
                        style={{
                            color: "#FFFFFF",
                            height: "20px",
                            width: "20px",
                            marginRight: "12px",
                            
                        }}
                    />
                    
                    <label
                        style={{
                            fontFamily:"Bold",
                            fontSize:"16px",
                            color:"white",
                            cursor: "pointer"
                        }}
                    >
                        SCRIVICI
                    </label>

                </div>
            </div>
        </div>
    )
}