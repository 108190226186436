import LeftArrow from "@material-ui/icons/ArrowBack";
import CartIcon from "../../images/shopping_cart.svg";
import CheckIcon from "@material-ui/icons/CheckBox";
import CheckBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RadioCheckIcon from "@material-ui/icons/RadioButtonChecked";
import RadioUncheckIcon from "@material-ui/icons/RadioButtonUnchecked";
import { useState, useEffect } from "react";
import { NumItem } from "../../components/lavorazioni/NumItem";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

export function LavorazioneDetailView(props){

    const { state } = useLocation();
    const navigate = useNavigate();

    const [arcataSuperiore, setArcataSuperiore] = useState(false);
    const [arcataInferiore, setArcataInferiore] = useState(false);

    const [scalaVita, setScalaVita] = useState(false);
    const [vita3D, setVita3D] = useState(false);
    const [chromascop, setChromascop] = useState(false);

    const [noteAggiuntive, setNoteAggiuntive] = useState("");
    const [noteChirurgia, setNoteChirurgia] = useState("");

    const [numerazioni, setNumerazioni] = useState([]);

    const [flag, setFlag] = useState(false);

    const [lavorazione, setLavorazione] = useState(state.lavorazione);

    useEffect(() => {

        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/lavorazioneDetail",{
                replace: false,
                state: {
                    lavorazione: lavorazione
                }
            })
            
        }, false);

        
        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        } 
    }, []);


    const NUM_1 = [
        "1.1",
        "1.2",
        "1.3",
        "1.4",
        "1.5",
        "1.6",
        "1.7",
        "1.8"
    ];

    const NUM_2 = [
        "2.1",
        "2.2",
        "2.3",
        "2.4",
        "2.5",
        "2.6",
        "2.7",
        "2.8"
    ];

    const NUM_3 = [
        "3.1",
        "3.2",
        "3.3",
        "3.4",
        "3.5",
        "3.6",
        "3.7",
        "3.8"
    ];

    const NUM_4 = [
        "4.1",
        "4.2",
        "4.3",
        "4.4",
        "4.5",
        "4.6",
        "4.7",
        "4.8"
    ];



    function addToCart(){
        const cart = localStorage.getItem("cart");
        if (cart) {
            let foundCart = JSON.parse(cart);
            let arcata = [];
            if(arcataSuperiore && arcataInferiore){
                arcata.push("Arcata superiore");
                arcata.push("Arcata inferiore");
            } else if(arcataSuperiore && !arcataInferiore){
                arcata.push("Arcata superiore");
            } else if(!arcataSuperiore && arcataInferiore){
                arcata.push("Arcata inferiore");
            }
            let variante = [];
            if(scalaVita){
                variante.push("Scala vita");
            } else if(vita3D) {
                variante.push("Vita 3D");
            } else if(chromascop){
                variante.push("Chromascop");
            }
            foundCart.cart.push({
                "_id": lavorazione._id,
                "arcata": arcata,
                "numerazione": numerazioni,
                "variante": variante,
                "ds_note_colorazione": noteChirurgia !== "" ? noteChirurgia : noteAggiuntive,
                "ds_name": lavorazione.ds_name,
                "ds_category": lavorazione.ds_category,
                "createdAt": lavorazione.createdAt,
                "updatedAt": lavorazione.updatedAt,
                "prezzo": lavorazione.prezzo,
                "listino": lavorazione.listino
            });
            localStorage.setItem("cart",JSON.stringify(foundCart));
            navigate("/step2",{
                replace: true,
                state: {
                    paziente: foundCart.paziente
                }
            })
        }

    }

    function handleNoteAggiuntive(event){
        if(event && event.target && (event.target.value || event.target.value === "")){
            setNoteAggiuntive(event.target.value);
            handleCheckVariante(scalaVita, vita3D, chromascop, event.target.value);
        }
    }

    function handleNoteChirurgia(event){
        if(event && event.target && (event.target.value || event.target.value === "")){
            setNoteChirurgia(event.target.value);
        }
    }

    function checkFlagNumerazioni(numerazione){
        if(numerazione.length > 0){
            if(lavorazione.varianti_prodotto){
                if (scalaVita || vita3D || chromascop) {
                    setFlag(true);
                } else {
                    setFlag(false);
                }
            } else {
                setFlag(true);
            }
        } else {
            setFlag(false);
        }
    }

    function handleNum(num){
        if(numerazioni.includes(num)){
            let tmpNumerazioni = numerazioni.filter(n => n !== num)
            setNumerazioni(tmpNumerazioni);
            checkFlagNumerazioni(tmpNumerazioni);
        } else {
            let tmpNumerazioni = numerazioni;
            tmpNumerazioni.push(num);
            setNumerazioni(tmpNumerazioni);
            checkFlagNumerazioni(tmpNumerazioni);
        }
        
    }

    function handleCheckVariante(scalaVita, vita3D, chromascop, noteAggiuntive){
        if((scalaVita || vita3D || chromascop) && noteAggiuntive !== ""){
            if(lavorazione.numerazione){
                if(numerazioni.length > 0){
                    setFlag(true);
                } else {
                    setFlag(false);
                }
            }
        } else {
            setFlag(false);
        }
    }

    function goBack(){
        const cart = localStorage.getItem("cart");
        if (cart) {
            let foundCart = JSON.parse(cart);
            navigate("/step2",{
                replace: true,
                state: {
                    paziente: foundCart.paziente
                }
            })
        }
    }

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                
                <div 
                    style={{
                        position:"fixed",
                        top:0,
                        zIndex:100,
                        width: "100%", 
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        
                    }}
                >
                    <div
                        
                        style={{
                            display: "flex",
                            justifyContent:"space-between",
                            flexDirection: "row",
                            marginLeft: "20%",
                            marginRight: "20%",
                            marginTop: "24px"
                        }}
                    >
                        <div
                            onClick={goBack}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                
                                alignItems: "center"
                            }}
                        >
                            <LeftArrow 
                                style={{
                                    color: "#6E6B77",
                                    height: "28px",
                                    width: "28px",
                                    cursor: "pointer"
                                }}
                            />
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize: "14px",
                                    color: "#6E6B77",
                                    textDecoration: "underline",
                                    marginBottom: "0px",
                                    marginLeft: "12px",
                                    marginTop: "0px",
                                    cursor: "pointer"
                                }}
                            >
                                Indietro
                            </p>
                        </div>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#BFBDC1",
                                textAlign:"left",
                                marginBottom: "0px",
                                marginTop: "8px"
                            }}
                        >
                            DETTAGLIO LAVORAZIONE
                        </p>
                        
                    </div>
                    <div 
                        style={{
                            height: "1px",
                            
                            backgroundColor:"#E9EBE7",
                            marginTop: "24px",
                            marginRight: "20%",
                            marginLeft: "20%"
                        }}
                    />
                </div>
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                        
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            
                            backgroundColor: "#FFFFFF",
                            borderBottomLeftRadius: "28px",
                            borderBottomRightRadius: "28px",
                            boxShadow: "0 4px 2px -2px #28242D1A",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            marginTop: "77px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                
                                marginLeft: "20%",
                                marginRight: "20%",
                                marginTop: "18px"
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "16px",
                                    color: "#94CC00",
                                    
                                    marginBottom: "0px",
                                    marginTop: "12px"
                                }}
                            >
                                {lavorazione.ds_category.toUpperCase()}
                            </p>
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "28px",
                                    color: "#28242D",
                                    
                                    marginBottom: "0px",
                                    marginTop: "8px"
                                }}
                            >
                                {lavorazione.ds_name}
                            </p>
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "20px",
                                    color: "#6E6B77",
                                    
                                    marginBottom: "32px",
                                    marginTop: "16px"
                                }}
                            >
                                € {lavorazione.prezzo.toString().replace(".",",")}
                            </p>
                        </div>
                    </div>
                    {lavorazione.arcata ? (

                        <>
                            <div
                                style={{
                                    display:"flex",
                                    flexDirection: "column",
                                    width:"100%",
                                    marginLeft: "20%",
                                    marginRight: "20%"
                                }}
                            >
                                <p
                                    style={{
                                        fontFamily: "Medium",
                                        fontSize: "16px",
                                        color: "#28242D",
                                        marginBottom: "0px",
                                        marginTop: "40px"
                                    }}
                                >
                                    Seleziona elemento
                                </p>
                                <p
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize: "12px",
                                        color: "#6E6B77",
                                        
                                        marginBottom: "8px",
                                        marginTop: "4px"
                                    }}
                                >
                                    Seleziona gli elementi da inserire in ordine
                                </p>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: "20%",
                                    marginRight: "20%",
                                    marginTop: "12px"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        height: "48px",
                                        borderRadius: "8px",
                                        backgroundColor: `${arcataSuperiore ? "#94CC00" : "#FFFFFF"}`,
                                        cursor: "pointer",
                                        border: `${arcataSuperiore ? "1px solid #94CC00" : "1px solid #E9EBE7"}`,
                                    }}
                                    onClick={() => {
                                        setArcataSuperiore(!arcataSuperiore);
                                        setFlag(arcataInferiore || !arcataSuperiore);
                                    }}
                                >
                                    {arcataSuperiore ? (
                                            <CheckIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#FFFFFF",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        ) : (
                                            <CheckBlankIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#BFBDC1",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        )
                                    }
                                    
                                    <p
                                        style={{
                                            fontFamily: "Regular",
                                            fontSize: "16px",
                                            color: `${arcataSuperiore ? "#FFFFFF" : "#BFBDC1"}`,
                                            marginLeft: "16px"
                                        }}
                                    >
                                        Arcata superiore
                                    </p>

                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        height: "48px",
                                        borderRadius: "8px",
                                        backgroundColor: `${arcataInferiore ? "#94CC00" : "#FFFFFF"}`,
                                        cursor: "pointer",
                                        border: `${arcataInferiore ? "1px solid #94CC00" : "1px solid #E9EBE7"}`,
                                        marginTop: "12px"
                                    }}
                                    onClick={() => { 
                                        setArcataInferiore(!arcataInferiore);
                                        setFlag(!arcataInferiore || arcataSuperiore);
                                    }}
                                    
                                >
                                    {arcataInferiore ? (
                                            <CheckIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#FFFFFF",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        ) : (
                                            <CheckBlankIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#BFBDC1",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        )
                                    }
                                    
                                    <p
                                        style={{
                                            fontFamily: "Regular",
                                            fontSize: "16px",
                                            color: `${arcataInferiore ? "#FFFFFF" : "#BFBDC1"}`,
                                            marginLeft: "16px"
                                        }}
                                    >
                                        Arcata inferiore
                                    </p>

                                </div>


                                {lavorazione.ds_name.toLowerCase().includes("mascherina pre-chirurgica") ? (
                                    <div
                                        style={{
                                            marginTop:"40px",
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "180px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontFamily: "Regular",
                                                fontSize: "12px",
                                                color: "#6E6B77",
                                                marginBottom: "8px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            Informazioni aggiuntive mascherina: su quali elementi mettiamo gli impianti?
                                        </p>
                                        <textarea 
                                            placeholder="Su quali elementi mettiamo gli impianti?"
                                            rows={4}
                                            onChange={handleNoteChirurgia}
                                            style={{
                                                fontFamily: "Regular",
                                                fontSize:"16px",
                                                color: "#282c34",
                                                borderRadius: "12px",
                                                border: "2px solid #E9EBE7",
                                                padding: "12px 24px",
                                                marginTop: "8px"
                                            }}
                                        />
                                        
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            
                        </>
                        
                    ) : (
                        ""
                    )}


                    {lavorazione.numerazione ? (

                        <>
                            <div
                                style={{
                                    display:"flex",
                                    flexDirection: "column",
                                    width:"100%",
                                    marginLeft: "20%",
                                    marginRight: "20%"
                                }}
                            >
                                <p
                                    style={{
                                        fontFamily: "Medium",
                                        fontSize: "16px",
                                        color: "#28242D",
                                        marginBottom: "0px",
                                        marginTop: "40px"
                                    }}
                                >
                                    Seleziona elementi
                                </p>
                                <p
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize: "12px",
                                        color: "#6E6B77",
                                        
                                        marginBottom: "8px",
                                        marginTop: "4px"
                                    }}
                                >
                                    Seleziona tutti i numeri dei denti da inserire in ordine
                                </p>
                            </div>
                            <div 
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginLeft: "20%",
                                    marginRight: "20%"
                                }}
                            >

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width:"49%"
                                    }}
                                >
                                    {NUM_1.map((el,index) => (
                                        <NumItem key={index} numerazioni={numerazioni} currentNum={el} handleNum={handleNum}/>
                                    ))}
                                    
                                </div>
                                <div style={{width: "2%"}}/>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width:"49%"
                                    }}
                                >
                                    {NUM_2.map((el,index) => (
                                        <NumItem key={index} numerazioni={numerazioni} currentNum={el} handleNum={handleNum}/>
                                    ))}

                                </div>

                            </div>
                            <div 
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginLeft: "20%",
                                    marginRight: "20%",
                                    marginTop: "32px",
                                    marginBottom: `${lavorazione.varianti_prodotto ? "0px" : "150px"}`
                                }}
                            >

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width:"49%"
                                    }}
                                >
                                    {NUM_3.map((el,index) => (
                                        <NumItem key={index} numerazioni={numerazioni} currentNum={el} handleNum={handleNum}/>
                                    ))}
                                    

                                </div>
                                <div style={{width: "2%"}}/>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width:"49%"
                                    }}
                                >
                                    {NUM_4.map((el,index) => (
                                        <NumItem key={index} numerazioni={numerazioni} currentNum={el} handleNum={handleNum}/>
                                    ))}

                                </div>

                            </div>
                        </>
                    ) : (
                        ""
                    )}



                    {lavorazione.varianti_prodotto ? (

                        <>
                            <div
                                style={{
                                    display:"flex",
                                    flexDirection: "column",
                                    width:"100%",
                                    marginLeft: "20%",
                                    marginRight: "20%"
                                }}
                            >
                                <p
                                    style={{
                                        fontFamily: "Medium",
                                        fontSize: "16px",
                                        color: "#28242D",
                                        marginBottom: "0px",
                                        marginTop: "40px"
                                    }}
                                >
                                    Seleziona variante
                                </p>
                                <p
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize: "12px",
                                        color: "#6E6B77",
                                        
                                        marginBottom: "8px",
                                        marginTop: "4px"
                                    }}
                                >
                                    Seleziona la categoria di colorazione dei denti
                                </p>
                            </div>


                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: "20%",
                                    marginRight: "20%",
                                    marginTop: "12px"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        height: "48px",
                                        borderRadius: "8px",
                                        backgroundColor: `${scalaVita ? "#94CC00" : "#FFFFFF"}`,
                                        cursor: "pointer",
                                        border: `${scalaVita ? "1px solid #94CC00" : "1px solid #E9EBE7"}`,
                                    }}
                                    onClick={() => { 
                                            setScalaVita(true);
                                            setVita3D(false);
                                            setChromascop(false);
                                            handleCheckVariante(true, false, false, noteAggiuntive);
                                    }}
                                >
                                    {scalaVita ? (
                                            <RadioCheckIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#FFFFFF",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        ) : (
                                            <RadioUncheckIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#BFBDC1",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        )
                                    }
                                    
                                    <p
                                        style={{
                                            fontFamily: "Regular",
                                            fontSize: "16px",
                                            color: `${scalaVita ? "#FFFFFF" : "#BFBDC1"}`,
                                            marginLeft: "16px"
                                        }}
                                    >
                                        Scala vita
                                    </p>

                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        height: "48px",
                                        borderRadius: "8px",
                                        backgroundColor: `${vita3D ? "#94CC00" : "#FFFFFF"}`,
                                        cursor: "pointer",
                                        border: `${vita3D ? "1px solid #94CC00" : "1px solid #E9EBE7"}`,
                                        marginTop: "12px"
                                    }}
                                    onClick={() => { 
                                            setScalaVita(false);
                                            setVita3D(true);
                                            setChromascop(false);
                                            handleCheckVariante(false, true, false, noteAggiuntive);
                                    }}
                                >
                                    {vita3D ? (
                                            <RadioCheckIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#FFFFFF",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        ) : (
                                            <RadioUncheckIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#BFBDC1",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        )
                                    }
                                    
                                    <p
                                        style={{
                                            fontFamily: "Regular",
                                            fontSize: "16px",
                                            color: `${vita3D ? "#FFFFFF" : "#BFBDC1"}`,
                                            marginLeft: "16px"
                                        }}
                                    >
                                        Vita 3D master
                                    </p>

                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        height: "48px",
                                        borderRadius: "8px",
                                        backgroundColor: `${chromascop ? "#94CC00" : "#FFFFFF"}`,
                                        cursor: "pointer",
                                        border: `${chromascop ? "1px solid #94CC00" : "1px solid #E9EBE7"}`,
                                        marginTop: "12px"
                                    }}
                                    onClick={() => { 
                                            setScalaVita(false);
                                            setVita3D(false);
                                            setChromascop(true);
                                            handleCheckVariante(false, false, true, noteAggiuntive);
                                    }}
                                >
                                    {chromascop ? (
                                            <RadioCheckIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#FFFFFF",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        ) : (
                                            <RadioUncheckIcon 
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#BFBDC1",
                                                    marginLeft: "16px"
                                                }}
                                            />
                                        )
                                    }
                                    
                                    <p
                                        style={{
                                            fontFamily: "Regular",
                                            fontSize: "16px",
                                            color: `${chromascop ? "#FFFFFF" : "#BFBDC1"}`,
                                            marginLeft: "16px"
                                        }}
                                    >
                                        Chromascop
                                    </p>

                                </div>
                            </div>
                            <div
                                style={{
                                    display:"flex",
                                    flexDirection: "column",
                                    
                                    marginLeft: "20%",
                                    marginRight: "20%"
                                }}
                            >
                                <p
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize: "12px",
                                        color: "#6E6B77",
                                        
                                        marginBottom: "8px",
                                        marginTop: "40px"
                                    }}
                                >
                                    Inserisci qui le indicazioni di tinta e/o specifiche tecniche come ad esempio corone singole o unite e tipologia di impianto.
                                </p>
                                
                            </div>
                            <div
                                style={{
                                    display:"flex",
                                    flexDirection: "column",
                                    
                                    marginLeft: "20%",
                                    marginRight: "20%",
                                    marginBottom: "150px"
                                }}
                            >
                                <textarea 
                                    placeholder="Inserisci qui le indicazioni di tinta e/o specifiche tecniche come ad esempio corone singole o unite e tipologia di impianto..."
                                    rows={4}
                                    onChange={handleNoteAggiuntive}
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize:"16px",
                                        color: "#282c34",
                                        borderRadius: "12px",
                                        border: "2px solid #E9EBE7",
                                        padding: "12px 24px",
                                        marginTop: "8px",
                                        
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div
                style={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    width: "100%",
                    height: "90px",
                    textAlign: "center",
                    backgroundColor: "#FFFFFF",
                    borderTopLeftRadius: "28px",
                    borderTopRightRadius: "28px",
                    boxShadow:"0px -6px 6px -6px #28242D0D",
                    zIndex:100,
                }}
            >
                <div
                    className={flag ? "myButton" : ""}
                    onClick={addToCart}
                    style={{
                        marginLeft: "30%",
                        marginRight: "30%",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: `${flag ? "#28242D" : "#E9EBE7"}`,
                        justifyContent: "center",
                        alignItems: "center",
                        height: "52px",
                        borderRadius: "28px",
                        marginTop: "20px",
                        padding: "0px 24px 0px 20px",
                        cursor: "pointer"
                    }}
                >
                    
                    <AddIcon 
                        style={{
                            color: "#FFFFFF",
                            height: "24px",
                            width: "24px",
                            cursor: "pointer"
                        }}
                    />
                    <label
                        style={{
                            fontFamily:"Bold",
                            fontSize:"16px",
                            color:"white",
                            cursor: "pointer",
                            marginLeft: "8px",
                            
                        }}
                    >
                        AGGIUNGI ALLA PRESCRIZIONE
                    </label>
                </div>
            </div>
        </div>
    );
}