import RightArrow from "@material-ui/icons/ArrowForward";

export function ProfileCell(props){

    function handleAction(){
        props.handleAction();
    }

    return (
        <div
            onClick={handleAction}
            style={{
                backgroundColor:"white",
                borderRadius: "16px",
                boxShadow: "0 4px 2px -2px #28242D1A",
                padding: "16px 24px 16px 24px",
                margin: "12px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                {props.icon}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                       
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "16px",
                            color: "#28242D",
                            marginBottom: "0px",
                            marginTop: "0px",
                            
                        }}
                    >
                        {props.mainTitle}
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "12px",
                            color: "#6D6A75",
                            marginBottom: "0px",
                            marginTop: "0px"
                        }}
                    >
                        {props.subtitle}
                    </p>
                </div>
            </div>
            <div
                style={{
                    display:"flex",
                    justifyContent: "right",
                    alignItems: "center"
                }}
            >
                <RightArrow 
                    style={{
                        color: "#BFBDC1",
                        height: "24px",
                        width: "24px",
                        
                    }}
                />
            </div>
        </div>
    )
}