import logo from './logo.svg';
import './App.css';
import './fonts/DINPro-Regular.otf';
import './fonts/DINPro-Medium.otf';
import './fonts/DINPro-Bold.otf';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { LoginView } from './views/login/LoginView';
import { HomeView } from './views/home/HomeView';
import { ProfileView } from './views/profile/ProfileView';
import { SupportView } from './views/profile/SupportView';
import { PersonalDataView } from './views/profile/PersonalDataView';
import { Step1View } from './views/ordini/Step1View';
import { Step2View } from './views/ordini/Step2View';
import { OrderSuccessView } from './views/ordini/OrderSuccessView';
import { Step3View } from './views/ordini/Step3View';
import { OrderDetail } from './views/ordini/OrderDetail';
import { RecuperaPasswordView } from './views/profile/RecuperaPasswordView';
import { LavorazioneDetailView } from './views/lavorazioni/LavorazioneDetailView';
import LoaderCustom from './components/utility/LoaderCustom';
import { useNavigate } from "react-router-dom";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

export const SERVICE_PATH = "https://dentalmax.azurewebsites.net//api";

export const formatPrice = price => {
  price = price ? price.toString() : '0,00';
  const numericPrice = parseFloat(price);
  const wholeNumber = Math.floor(numericPrice);
  const decimalPart = numericPrice - wholeNumber;

  let formattedPrice = wholeNumber.toLocaleString('en-US');

  if (decimalPart > 0.5) {
    formattedPrice += ',50';
  } else {
    formattedPrice += ',00';
  }

  return formattedPrice;
};

function App() {

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
      const loggedInUser = localStorage.getItem("user");
      if (loggedInUser && !user) {
        const foundUser = JSON.parse(loggedInUser);
        login(foundUser);
      } else {
        setIsLoading(false);
      }
  }, []);

  function login(foundUser){
   
    setIsLoading(true);
    axios.post(SERVICE_PATH + '/users/signin', {
        email: foundUser.email,
        password: foundUser.password
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if(response.data){
            
            setIsLoading(false);
            setUser(response.data.user);
            localStorage.setItem('user', JSON.stringify({email:foundUser.email, password: foundUser.password, token: response.data.token})); 
            
        }
    }).catch((error) => {
        console.error(error);
        setIsLoading(false);
        
    })
}

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            marginTop: "-50px",
            marginLeft: "-50px",
            width: "100%",
            height: "100%",
            zIndex: "1000"
          }}
        >
          <LoaderCustom
              style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%"
              }}
          />
        </div>
      ) : (

        <div
          style={{
            display:'flex',
            backgroundColor:"#F6F8F5",
            justifyContent: "center",
            alignItems: "center",
            
          }}
        >
          <Router>
            <Routes>
              <Route exact path="/" element={<HomeView user={user} />} />
              <Route exact path="/signin" element={<LoginView getUser={(utente) => setUser(utente)}/>} />
              <Route exact path="/orderDetail" element={<OrderDetail user={user}/>} />
              <Route exact path="/profile" element={<ProfileView user={user}/>} />
              <Route exact path="/profile/personalData" element={<PersonalDataView user={user}/>} />
              <Route exact path="/profile/support" element={<SupportView user={user} />} />
              <Route exact path="/step1" element={<Step1View user={user} />} />
              <Route exact path="/step2" element={<Step2View user={user} />} />
              <Route exact path="/step3" element={<Step3View user={user}/>} />
              <Route exact path="/lavorazioneDetail" element={<LavorazioneDetailView user={user} />} />
              <Route exact path="/orderSuccess" element={<OrderSuccessView user={user} />} />
              <Route exact path="/recoveryPsw" element={<RecuperaPasswordView />} />
            </Routes>
          </Router>
          
        </div>
      )}
      
    </div>
  );
}

export default App;
