import SuccessIcon from "@material-ui/icons/ThumbUp";
import WhatsAppIcon from "../../images/icon_whatsapp.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export function OrderSuccessView(){

    const navigate = useNavigate();
    const { state } = useLocation();

    const [ordine, setOrdine] = useState(state.ordine);

    useEffect(() => {

        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/orderSuccess",{
                replace: false,
                state: {
                    ordine: ordine
                }
            })
            
        }, false);

        
        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        } 
    }, []);

    function handleWhatsapp(){
        window.open(`https://wa.me/393892078710?text=${ordine.text_prefill}`);
    }

    function goToHome(){
        navigate("/",{
            replace: false
        })
    }

    return (
        <div
            style={{
                marginTop: "100px",
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <SuccessIcon 
                style={{
                    color: "#94CC00",
                    height: "40px",
                    width: "40px",
                }}
            />
            <p      
                style={{
                    fontFamily:"Medium",
                    fontSize: "28px",
                    color: "#94CC00",
                    marginTop: "12px"
                }}>
                Grazie!
            </p>
            <p      
                style={{
                    fontFamily:"Regular",
                    fontSize: "28px",
                    color: "#28242D",
                    marginTop: "18px"
                }}>
                Il tuo ordine è stato inviato con successo!
            </p>
            <p      
                style={{
                    fontFamily:"Regular",
                    fontSize: "16px",
                    color: "#6E6B77",
                    marginTop: "18px",
                    textAlign: "center"
                }}>
                Controlla la tua casella mail, riceverai un messaggio di conferma o verrai contattato dall staff dentalMax nel caso vengano riscontrate delle anomalie. L'ordine sarà completo una volta inviate le foto del paziente.
            </p>
            <p      
                style={{
                    fontFamily:"Regular",
                    fontSize: "16px",
                    color: "#6E6B77",
                    marginTop: "18px",
                    textAlign: "center",
                    textDecoration: "underline"
                }}>
                L'ordine sarà completo una volta inviate le foto del paziente:
            </p>
            <div
                className="myButton"
                onClick={handleWhatsapp}
                style={{
                    width: "295px",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#94CC00",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "52px",
                    borderRadius: "28px",
                    marginTop: "32px",
                    padding: "0px 24px 0px 20px",
                    cursor: "pointer"
                }}
            >
                <img 
                    src={WhatsAppIcon}
                    alt=''
                    style={{
                        color: "#FFFFFF",
                        height: "24px",
                        width: "24px",
                        
                    }}
                />
                <label
                    style={{
                        fontFamily:"Bold",
                        fontSize:"16px",
                        color:"white",
                        cursor: "pointer",
                        marginLeft: "16px"
                    }}
                >
                    INVIA FOTO PAZIENTE
                </label>
            </div>
            <div
                onClick={goToHome}
                style={{
                    textAlign: "center",
                    marginTop: "80px",
                    width: "100%"
                }}
            >
                <label
                    style={{
                        fontFamily: "Medium",
                        fontSize: "16px",
                        color: "#BFBDC1",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                >
                    TORNA AGLI ORDINI
                </label>
            </div>
        </div>
    )
}