import AddIcon from "@material-ui/icons/Add";

export function LavorazioneCell(props){

    function handleLavorazione(){
        props.handleLavorazione(props.lavorazione);
    }

    return (
        <div
            onClick={handleLavorazione}
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FFFFFF",
                borderRadius: "16px",
                boxShadow: "0 4px 2px -2px #28242D1A",
                padding: "0px 24px",
                cursor: "pointer",
                marginTop: "12px"
            }}
        >
            <p
                style={{
                    fontFamily: "Medium",
                    fontSize: "16px",
                    color: "#28242D",
                    textAlign:"left",
                    marginBottom: "0px",
                    marginTop: "12px"
                }}
            >
                {props.lavorazione.ds_name}
            </p>
            <div
                style={{
                    display:"flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "12px"
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "10px",
                        color: "#BFBDC1",
                        textAlign:"left",
                        marginBottom: "0px",
                        marginTop: "0px"
                    }}
                >
                    {props.lavorazione.ds_category.toUpperCase()}
                    <span
                        style={{
                            fontSize: "12px",
                            color: "#6E6B77"
                        }}
                    >
                        &nbsp; | € {props.lavorazione.prezzo.toString().replace(".",",")}
                    </span>
                </p>
                <div>
                    <div
                        style={{
                            position:"flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    >
                        <label
                            style={{
                                fontSize: "12px",
                                color: `${props.isInCart ? "#E9EBE7" : "#94CC00"}`,
                                textDecoration: "underline",
                                width: "80px",
                                marginRight: "12px",
                                cursor: "pointer"
                            }}
                        >
                            Aggiungi
                        </label>
                        <div
                            style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: `${props.isInCart ? "#E9EBE7" : "#94CC00"}`,
                                borderRadius: "12px",
                                display: "inline-block",
                                textAlign: "center",
                                marginBottom: "8px"
                            }}
                        >
                            <AddIcon 
                                style={{
                                    color: "#FFFFFF",
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px"
                                }}
                            />
                        </div>
                        
                    </div>
                </div>



            </div>
        </div>
    )
}