import LeftArrow from "@material-ui/icons/ArrowBack";
import { PersonalDataRow } from "../../components/profile/PersonalDataRow";
import { LavorazioneCartCell } from "../../components/lavorazioni/LavorazioneCartCell";
import { useState, useEffect } from "react";
import { CustomButton } from "../../components/utility/CustomButton";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Email";
import LogoFooter from "../../images/logo_footer.png";
import CheckIcon from "@material-ui/icons/Check";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoaderCustom from "../../components/utility/LoaderCustom";
import { formatPrice } from "../../App";
import { SERVICE_PATH } from "../../App";
import ExitOrderModal from "../../components/ordini/ExitOrderModal";
import DeleteIcon from "@material-ui/icons/Delete";

export function Step3View(props){

    const navigate = useNavigate();

    const [noteOrdine, setNoteOrdine] = useState("");
    const [sconto, setSconto] = useState("");
    const [scontoIsNotValid, setScontoIsNotValid] = useState(false);
    const [carrello, setCarrello] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showScontoButton, setShowScontoButton] = useState(true);
    const [open, setOpen] = useState(false);
    const [lavorazioneToDelete, setLavorazioneToDelete] = useState({});

    useEffect(() => {
        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/step3",{
                replace: false
            })
            
        }, false);

        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        }  else {
            getCarrello()
        }
    }, []);

    function getCarrello(){
        const cart = localStorage.getItem("cart");
        const loggedInUser = localStorage.getItem("user");
        
          
        if (cart && loggedInUser) {
            const foundCart = JSON.parse(cart);
            const foundUser = JSON.parse(loggedInUser);
            
            axios.post(SERVICE_PATH + '/orders/cart', {
                paziente: foundCart.paziente,
                cart: foundCart.cart,
                credential: foundCart.credential
            }, {
                headers: {
                    "Access-Control-Allow-Origin" : "*",
                    "Access-Control-Allow-Methods": "POST, GET, PUT",
                    "Access-Control-Allow-Headers" : "Content-type",
                    "Content-type": "Application/json",
                    'Authorization' : `Bearer ${foundUser.token}`
                }
            }).then((response) => {
                if(response.data){
                    
                    setIsLoading(false);
                    setCarrello({...response.data});
                    
                }
            }).catch((error) => {
                console.error(error);
                setIsLoading(false);
                
            })
            
        }
    }

    function handleTextNote(event){
        if(event && event.target && (event.target.value || event.target.value === "")){
            setNoteOrdine(event.target.value);
        }
    }

    function deleteLavorazione(){
        
        const cart = localStorage.getItem("cart");
       
        if (cart) {
            let foundCart = JSON.parse(cart);
            foundCart.cart = foundCart.cart.filter(elem => elem._id !== lavorazioneToDelete._id_lavorazione);
            localStorage.setItem("cart",JSON.stringify(foundCart));
            setOpen(false);
            if(foundCart.cart.length === 0){
                goBack();
            } else {
                getCarrello();
            }
            
        }

    }

    function handleSconto(event){
        if(event && event.target && (event.target.value || event.target.value === "")){
            setSconto(event.target.value);
        }
    }

    function applicaSconto(){
        const cart = localStorage.getItem("cart");
        const loggedInUser = localStorage.getItem("user");
        
          
        if (cart && loggedInUser) {
            let foundCart = JSON.parse(cart);
            const foundUser = JSON.parse(loggedInUser);

            foundCart.paziente.sconto = sconto
            
            axios.post(SERVICE_PATH + '/orders/cart', {
                paziente: foundCart.paziente,
                cart: foundCart.cart,
                credential: foundCart.credential
            }, {
                headers: {
                    "Access-Control-Allow-Origin" : "*",
                    "Access-Control-Allow-Methods": "POST, GET, PUT",
                    "Access-Control-Allow-Headers" : "Content-type",
                    "Content-type": "Application/json",
                    'Authorization' : `Bearer ${foundUser.token}`
                }
            }).then((response) => {
                if(response.data){
                    if(response.data.sconto === 0){
                        setScontoIsNotValid(true);
                    } else {
                        setScontoIsNotValid(false);

                    }
                    setIsLoading(false);
                    setCarrello({...response.data});
                    
                }
            }).catch((error) => {
                console.error(error);
                setIsLoading(false);
                
            })
            
        }
    }

    function confermaOrdine(){
        const loggedInUser = localStorage.getItem("user");
        
          
        if (loggedInUser) {
            
            const foundUser = JSON.parse(loggedInUser);

            let cart = carrello;

            cart.paziente.sesso = cart.paziente.sesso.value === "" ? cart.paziente.sesso.value : cart.paziente.sesso.label;
            cart.paziente.forma_viso = cart.paziente.forma_viso.value === "" ? cart.paziente.forma_viso.value : cart.paziente.forma_viso.label;
            
            cart.ds_note = noteOrdine;
            
            axios.post(SERVICE_PATH + '/orders', cart, {
                headers: {
                    "Access-Control-Allow-Origin" : "*",
                    "Access-Control-Allow-Methods": "POST, GET, PUT",
                    "Access-Control-Allow-Headers" : "Content-type",
                    "Content-type": "Application/json",
                    'Authorization' : `Bearer ${foundUser.token}`
                }
            }).then((response) => {
                if(response.data){
                    localStorage.removeItem('cart');
                    navigate("/orderSuccess",{
                        replace: false,
                        state: {
                            ordine: response.data
                        }
                    })
                    
                }
            }).catch((error) => {
                console.error(error);
                setIsLoading(false);
                
            })
            
        }
    }

    function goBack(){
        navigate("/step2", {
            replace: false,
            state: {
                paziente: carrello.paziente
            }
        });
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = (lavorazione) => {
        setLavorazioneToDelete(lavorazione);
        setOpen(true);
    }

    function handleEmail(){
        window.open("mailto:areadigitale@dentalmax.eu");
    }

    function handlePhone(){
        window.open('tel:3476480095');
    }

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                
                <div 
                    style={{
                        position:"fixed",
                        top:0,
                        zIndex:100,
                        width: "100%",
                        
                        backgroundColor: "#28242D",
                        borderBottomLeftRadius: "28px",
                        WebkitBorderBottomRightRadius: "28px",
                        boxShadow: "0 4px 2px -2px #28242D1A",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        
                    }}
                >
                    <div
                        onClick={goBack}
                        style={{
                            display: "flex",
                            justifyContent:"start",
                            alignItems: "center",
                            marginLeft: "20%",
                            marginTop: "24px"
                        }}
                    >
                        <LeftArrow 
                            style={{
                                color: "#BFBDC1",
                                height: "28px",
                                width: "28px",
                                cursor: "pointer"
                            }}
                        />
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "14px",
                                color: "#BFBDC1",
                                textDecoration: "underline",
                                marginBottom: "0px",
                                marginLeft: "12px",
                                marginTop: "0px",
                                cursor: "pointer"
                            }}
                        >
                            Indietro
                        </p>
                        
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent:"left",
                            marginLeft: "20%",
                            marginRight: "20%"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "28px",
                                color: "#FFFFFF",
                                textAlign:"left",
                                marginBottom: "0px",
                                marginTop: "24px"
                            }}
                        >
                            Il tuo carrello
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#BFBDC1",
                                textAlign:"left",
                                marginBottom: "28px",
                                marginTop: "4px"
                            }}
                        >
                            Qui sotto trovi il riepilogo della prescrizione inserita. Controlla che tutto sia corretto e invialo.
                            
                        </p>
                    </div>
                </div>
                
            </div>
            <p
                style={{
                    fontFamily: "Medium",
                    fontSize: "20px",
                    color: "#94CC00",
                    
                    marginBottom: "24px",
                    marginTop: "210px"
                }}
            >
                Riepilogo dati paziente
            </p>
            <PersonalDataRow myKey="NOME COGNOME" value={carrello ? carrello.paziente.nome_cognome : "-"}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="SESSO" value={carrello ? (carrello.paziente.sesso.value === "" ? "-" : carrello.paziente.sesso.label) : "-"}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="ALLERGIE" value={carrello && carrello.paziente.allergie !== "" ? carrello.paziente.allergie : "-"}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="FORMA DEL VISO" value={carrello ? (carrello.paziente.forma_viso.value === "" ? "-" : carrello.paziente.forma_viso.label) : "-"}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <div
                style={{
                    marginTop:"28px",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "60px"
                }}
            >
                <label
                    style={{
                        fontFamily: "Medium",
                        fontSize: "12px",
                        color: "#BFBDC1",  
                        marginBottom: "8px",
                    }}
                >
                    NOTE PAZIENTE
                </label>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#28242D",
                        textAlign:"left",
                        margin:"0px",
                        
                    }}
                >
                    {carrello && carrello.paziente.ds_note !== "" ? carrello.paziente.ds_note : "-"}
                </p>
            </div>
            <p
                style={{
                    fontFamily: "Medium",
                    fontSize: "20px",
                    color: "#94CC00",
                    
                    marginBottom: "24px",
                    marginTop: "20px"
                }}
            >
                Riepilogo lavorazioni
            </p>
            <div 
                style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor:"#BFBDC1",
                    marginTop: "20px"
                }}
            />
            {carrello ? (carrello.lavorazioni.map((el,index) => (
                <LavorazioneCartCell key={el._id_lavorazione} lavorazione={el} onDelete={handleOpen} />
            ))) : ""}
            

           <div
                style={{
                    padding: "20px",
                    border: "2px solid #28242D",
                    borderRadius: "28px",
                    textAlign: "center",
                    marginTop: "60px"
                }}
           >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "20px",
                        color: "#28242D",
                        marginBottom: "8px",
                    }}
                >
                    Attenzione!
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        marginBottom: "8px",
                    }}
                >
                    Ricordarsi di inviare STL o impronte dentali analogiche del paziente. Invia i file a&nbsp; 
                    <span
                        onClick={handleEmail}
                        style={{
                            fontFamily: "Medium",
                            color: "#94CC00",
                            cursor: "pointer"
                        }}
                    >
                        areadigitale@dentalmax.eu
                    </span>
                </p>
           </div>

           <p
                style={{
                    fontFamily: "Medium",
                    fontSize: "20px",
                    color: "#94CC00",
                    marginBottom: "8px",
                    marginTop: "60px"
                }}
            >
                Note prescrizione
            </p>
            <p
                style={{
                    fontFamily: "Regular",
                    fontSize: "14px",
                    color: "#6E6B77",
                    
                }}
            >
                Inserisci indicazioni utili al laboratorio dentalMax per preparare il tuo ordine, come la data della prova paziente.
            </p>
            <textarea 
                placeholder="Scrivi qui..."
                rows={4}
                onChange={handleTextNote}
                style={{
                    fontFamily: "Regular",
                    fontSize:"16px",
                    color: "#282c34",
                    borderRadius: "12px",
                    border: "2px solid #E9EBE7",
                    padding: "12px 24px",
                    marginTop: "8px",
                    width: "100%"
                }}
            />
            <p
                style={{
                    fontFamily: "Medium",
                    fontSize: "20px",
                    color: "#94CC00",
                    marginBottom: "8px",
                    marginTop: "60px"
                }}
            >
                Codice promo
            </p>
            <p
                style={{
                    fontFamily: "Regular",
                    fontSize: "14px",
                    color: "#6E6B77",
                    
                }}
            >
                Hai ricevuto un codice sconto? Inseriscilo qui sotto per applicarlo alla tua prescrizione.
            </p>
            <input 
                type="text"
                className="input"
                value={sconto}
                placeholder="Inserisci..."
                onChange={handleSconto}
                style={{
                    marginTop:"8px",
                    width: "100%"
                }}
            />



            {scontoIsNotValid === true ? (
                carrello.total > 131.5 ? (
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#FF2F60",
                            marginBottom: "12px",
                            marginTop: "6px"
                        }}
                    >
                        Il codice sconto che hai inserito non è valido.
                    </p>
                ) : (
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#FF2F60",
                            marginBottom: "12px",
                            marginTop: "6px"
                        }}
                    >
                        Il codice sconto può essere utilizzato solo per ordini superiori a 131,50 €.
                    </p>
                )
            ) : (
                ""
            )}
            {showScontoButton === true ? (

            
                <div
                    style={{
                        width: "100%",
                        
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            textAlign: "center",
                            
                            marginTop: "23px",
                            width: "50%"
                        }}
                    >
                        
                        <CustomButton 
                            titleTxt="APPLICA SCONTO"
                            myColor="#28242D"
                            handleAction={applicaSconto}
                        />
                        
                    </div>
                </div>
            ) : (
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#28242D",
                        marginBottom: "0px",
                        marginTop: "6px"
                    }}
                >
                    Il codice sconto è valido. Il tuo sconto di 131,50 € è stato applicato correttamente.
                </p>
            )}




            <p
                style={{
                    fontFamily: "Medium",
                    fontSize: "20px",
                    color: "#94CC00",
                    marginBottom: "8px",
                    marginTop: "60px"
                }}
            >
                Lavorazioni aggiuntive
            </p>
            <p
                style={{
                    fontFamily: "Regular",
                    fontSize: "16px",
                    color: "#6E6B77",
                    
                }}
            >
                Per prenotare ore di&nbsp;
                <span
                    style={{
                        fontFamily: "Medium",
                        color: "#28242D"
                    }}
                >
                    consulenza tecnica
                </span> 
                &nbsp;o lavorazioni di&nbsp;
                <span
                    style={{
                        fontFamily: "Medium",
                        color: "#28242D"
                    }}
                >
                ortodonzia 
                </span> 
                &nbsp;contatta il nostro specialist e richiedi un preventivo.
            </p>
            <div
                onClick={handlePhone}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                    cursor: "pointer"
                }}
            >
                <PhoneIcon 
                    style={{
                        color: "#28242D",
                        height: "20px",
                        width: "20px",
                        marginRight: "12px"
                    }}
                />
                <p
                
                    style={{
                        fontFamily:"Medium",
                        fontSize: "16px",
                        color: "#28242D",
                        textDecoration: "underline"
                    }}>
                    +39 011 4222925 / +39 389 207 8710
                </p>
            </div>
            <div
                onClick={handleEmail}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                    cursor: "pointer"
                }}
            >
                <MailIcon 
                    style={{
                        color: "#28242D",
                        height: "20px",
                        width: "20px",
                        marginRight: "12px"
                    }}
                />
                <p
                    style={{
                        fontFamily:"Medium",
                        fontSize: "16px",
                        color: "#28242D",
                        textDecoration: "underline"
                    }}>
                    areadigitale@dentalmax.eu
                </p>
            </div>
            <div
                style={{
                    textAlign: "center",
                    marginTop:"60px"
                }}
            >
                <img
                    src={LogoFooter}
                    alt=''
                    style={{
                        height: "60px",
                        display: "inline-block",
                        
                    }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "60px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#FFFFFF",
                        borderTopLeftRadius: "28px",
                        borderTopRightRadius: "28px",
                        boxShadow:"0px -6px 6px -6px #28242D0D",
                        minHeight: "250px",
                        width: "100%",
                        position:"absolute"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "end",
                            marginRight: "20%",
                            marginLeft: "20%",
                            marginTop: "12px",
                            marginBottom: "0px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily:"Medium",
                                fontSize: "12px",
                                color: "#BFBDC1",
                                marginBottom: "0px"
                            }}>
                            Totale lavorazioni:
                        </p>
                        <p
                            style={{
                                fontFamily:"Medium",
                                fontSize: "16px",
                                color: "#6E6B77",
                                marginBottom: "0px"
                            }}>
                            € {carrello ? formatPrice(carrello.total) : "-"}
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "end",
                            marginRight: "20%",
                            marginLeft: "20%",
                            marginTop: "0px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily:"Medium",
                                fontSize: "12px",
                                color: "#BFBDC1",
                                marginBottom: "0px"
                                
                            }}>
                            Spese di spedizione:
                        </p>
                        <p
                            style={{
                                fontFamily:"Regular",
                                fontSize: "12px",
                                color: "#6E6B77",
                                marginBottom: "0px"
                            }}>
                            a carico del cliente
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "end",
                            marginRight: "20%",
                            marginLeft: "20%",
                            marginTop: "2px",
                            marginBottom: "0px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily:"Medium",
                                fontSize: "14px",
                                color: "#94CC00",
                                marginBottom: "0px"
                            }}>
                            TOTALE ORDINE:
                        </p>
                        <p
                            style={{
                                fontFamily:"Bold",
                                fontSize: "20px",
                                color: "#28242D",
                                marginBottom: "0px"
                            }}>
                            € {carrello ? formatPrice(carrello.total) : "-"}
                        </p>
                    </div>
                    <div 
                        style={{
                            height: "1px",
                            
                            backgroundColor:"#E9EBE7",
                            marginTop: "24px",
                            marginRight: "20%",
                            marginLeft: "20%"
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent:"space-between",
                            alignItems: "center",
                            marginLeft: "20%",
                            marginRight: "20%"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "12px",
                                    color: "#BFBDC1",
                                    marginBottom: "0px",
                                    marginTop: "18px"
                                }}
                            >
                                Nuova prescrizione
                            </p>
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "16px",
                                    color: "#6E6B77",
                                    marginBottom: "0px",
                                    marginTop: "2px"
                                }}
                            >
                                Step 3/3
                            </p>
                        </div>
                        <div
                            className="myButton"
                            onClick={confermaOrdine}
                            style={{
                                width: "180px",
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#94CC00",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "52px",
                                borderRadius: "28px",
                                marginTop: "18px",
                                padding: "0px 24px 0px 20px",
                                cursor: "pointer"
                            }}
                        >
                            
                            <label
                                style={{
                                    fontFamily:"Bold",
                                    fontSize:"16px",
                                    color:"white",
                                    cursor: "pointer",
                                    marginRight: "12px"
                                }}
                            >
                                CONFERMA
                            </label>
                            <CheckIcon 
                            
                                style={{
                                    color: "#FFFFFF",
                                    height: "24px",
                                    width: "24px",
                                    
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ExitOrderModal isOpen={open} onClose={handleClose}>
                <>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"20px",
                            color: "#282c34",
                            textAlign: "center !important",
                            marginTop: "0px"
                        }}
                    >
                        Eliminare lavorazione?
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#6E6B77",
                            textAlign:"center",
                            marginTop: "8px",
                            marginBottom:"0px"
                        }}
                    >
                        Sei sicuro di voler eliminare questa lavorazione?
                    </p>
                    
                    <div
                        className="myButton"
                        onClick={deleteLavorazione}
                        style={{
                            marginRight:"20px",
                            marginLeft: "20px",
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#FF2F60",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "52px",
                            borderRadius: "28px",
                            marginTop: "18px",
                            padding: "0px 24px 0px 20px",
                            cursor: "pointer"
                        }}
                    >

                        <DeleteIcon 
                        
                            style={{
                                color: "#FFFFFF",
                                height: "24px",
                                width: "24px",
                                
                            }}
                        />   
                        <label
                            style={{
                                fontFamily:"Bold",
                                fontSize:"16px",
                                color:"white",
                                cursor: "pointer",
                                marginLeft: "12px"
                            }}
                        >
                            ELIMINA LAVORAZIONE
                        </label>
                        
                    </div>
                    
                    <div
                        onClick={handleClose}
                        style={{
                            textAlign: "center",
                            marginTop: "30px",
                            width: "100%"
                        }}
                    >
                        <label
                            style={{
                                fontFamily: "Medium",
                                fontSize: "16px",
                                color: "#BFBDC1",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                        >
                            ANNULLA
                        </label>
                    </div>
                </>
            </ExitOrderModal>
        </div>
    )
}