import LeftArrow from "@material-ui/icons/ArrowBack";
import { StatusChip } from "../../components/collection/StatusChip";
import BlockIcon from "../../images/statusIcon/block.svg";
import ListBulletIcon from "../../images/statusIcon/format_list_bulleted.svg";
import InventoryIcon from "../../images/statusIcon/inventory.svg";
import PauseIcon from "../../images/statusIcon/pause.svg";
import ScheduleIcon from "../../images/statusIcon/schedule.svg";
import TaskAltIcon from "../../images/statusIcon/task_alt.svg";
import { PersonalDataRow } from "../../components/profile/PersonalDataRow";
import { LavorazioneCartCell } from "../../components/lavorazioni/LavorazioneCartCell";
import WhatsAppIcon from "../../images/icon_whatsapp.png";
import LogoFooter from "../../images/logo_footer.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import * as moment from "moment";
import { formatPrice } from "../../App";

export function OrderDetail(){

    const { state } = useLocation();
    const navigate = useNavigate();

    const [ordine, setOrdine] = useState(state.ordine);

    useEffect(() => {

        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/orderDetail",{
                replace: false,
                state: {
                    ordine: ordine
                }
            })
            
        }, false);

        
        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        }
    }, []);

    function changeStatus(){}

    function handleWhatsapp(){
        window.open(`https://wa.me/393892078710?text=A%20seguire%20le%20foto%20relative%20alla%20prescrizione%20#%20${ordine.order_code}`);
    }

    function getStatus(){
        let statusFound = CHIPS.find((status) => (parseInt(status.value) === parseInt(ordine.cd_status)));
        return statusFound;
    }

    const CHIPS = [
        {
          label: 'TUTTI',
          value: 0,
          icon: ListBulletIcon,
          color: '#6E6B77',
        },
        {
          label: 'IN ATTESA',
          value: 100,
          icon: ScheduleIcon,
          color: '#F7C336',
        },
        {
          label: 'CONFERMATI',
          value: 200,
          icon: TaskAltIcon,
          color: '#94CC00',
        },
        {
          label: 'CONSEGNATI',
          value: 300,
          icon: InventoryIcon,
          color: '#28242D',
        },
        {
          label: 'RIFIUTATI',
          value: 500,
          icon: BlockIcon,
          color: '#ff2f5f',
        },
        {
          label: 'IN SOSPESO',
          value: 400,
          icon: PauseIcon,
          color: '#bfbdc1',
        },
    ];

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                
                <div 
                    style={{
                        position:"fixed",
                        top:0,
                        zIndex:100,
                        width: "100%", 
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        
                    }}
                >
                    <div
                        
                        style={{
                            display: "flex",
                            justifyContent:"space-between",
                            flexDirection: "row",
                            marginLeft: "20%",
                            marginRight: "20%",
                            marginTop: "24px"
                        }}
                    >
                        <div
                            onClick={() => navigate("/",{
                                replace: true
                            })}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                
                                alignItems: "center"
                            }}
                        >
                            <LeftArrow 
                                style={{
                                    color: "#6E6B77",
                                    height: "28px",
                                    width: "28px",
                                    cursor: "pointer"
                                }}
                            />
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize: "14px",
                                    color: "#6E6B77",
                                    textDecoration: "underline",
                                    marginBottom: "0px",
                                    marginLeft: "12px",
                                    marginTop: "0px",
                                    cursor: "pointer"
                                }}
                            >
                                Indietro
                            </p>
                        </div>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#BFBDC1",
                                textAlign:"left",
                                marginBottom: "0px",
                                marginTop: "8px"
                            }}
                        >
                            DETTAGLIO PRESCRIZIONE
                        </p>
                        
                    </div>
                    <div 
                        style={{
                            height: "1px",
                            
                            backgroundColor:"#E9EBE7",
                            marginTop: "24px",
                            marginRight: "20%",
                            marginLeft: "20%"
                        }}
                    />
                </div>
                
            </div>
            


            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    marginLeft: "-20%"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        
                        backgroundColor: "#FFFFFF",
                        borderBottomLeftRadius: "28px",
                        borderBottomRightRadius: "28px",
                        boxShadow: "0 4px 2px -2px #28242D1A",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginTop: "77px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent:"space-between",
                            alignItems: "center",
                            marginLeft: "20%",
                            marginRight: "20%",
                            marginTop: "18px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "28px",
                                color: "#28242D",
                                
                                marginBottom: "0px",
                                marginTop: "8px"
                            }}
                        >
                            {ordine.ds_name}
                        </p>
                        <StatusChip setStatus={changeStatus} isSelected={true} status={getStatus()} isDetail={true}/>
                    </div>
                    <div
                        style={{
                            marginRight:"20%",
                            marginLeft: "20%"
                        }}
                    >
                        <PersonalDataRow myKey="PAZIENTE" value={ordine.paziente.nome_cognome}/>
                        <div 
                            style={{
                                height: "1px",
                                
                                backgroundColor:"#E9EBE7",
                                marginTop: "8px",
                                
                            }}
                        />
                        <PersonalDataRow myKey="DATA ORDINE" value={moment(ordine.createdAt).format("DD/MM/YYYY, HH:mm")}/>
                        <div 
                            style={{
                                height: "1px",
                                
                                backgroundColor:"#E9EBE7",
                                marginTop: "8px",
                                
                            }}
                        />
                        {/* <PersonalDataRow myKey="CONSEGNA PREVISTA" value="-"/> */}
                        
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "20px",
                                marginBottom: "28px"
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "12px",
                                    color: "#BFBDC1",
                                    textAlign:"left",
                                    margin:"0px",
                                    
                                }}
                            >
                                TOTALE PRESCRIZIONE
                            </p>
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "20px",
                                    color: "#28242D",
                                    textAlign:"right",
                                    margin:"0px",
                                    
                                }}
                            >
                                € {formatPrice(ordine.total)}
                            </p>
                        </div>

                    </div>
                    {ordine.ds_rejected_order_notes && ordine.ds_rejected_order_notes !== "" ? (

                    
                        <div
                            style={{
                                border: "2px solid #FF2F60",
                                borderRadius: "28px",
                                marginRight: "18%",
                                marginLeft: "18%"
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "12px",
                                    color: "#BFBDC1",
                                    textAlign:"left",
                                    marginLeft:"3%",
                                    marginRight: "3%",
                                    marginTop: "18px"
                                }}
                            >
                                NOTE RIFIUTO
                            </p>
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize: "16px",
                                    color: "#28242D",
                                    textAlign:"left",
                                    marginBottom:"18px",
                                    marginLeft:"3%",
                                    marginRight: "3%",
                                }}
                            >
                                {ordine.ds_rejected_order_notes}
                            </p>
                        </div>
                    ) : (
                        ""
                    )
                }
                {ordine.ds_note_suspend_admin && ordine.ds_note_suspend_admin !== "" ? (

                    
                    <div
                        style={{
                            border: "2px solid #bfbdc1",
                            borderRadius: "28px",
                            marginRight: "18%",
                            marginLeft: "18%"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#BFBDC1",
                                textAlign:"left",
                                marginLeft:"3%",
                                marginRight: "3%",
                                marginTop: "18px"
                            }}
                        >
                            PRESCRIZIONE SOSPESA PERCHÈ:
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#28242D",
                                textAlign:"left",
                                marginBottom:"18px",
                                marginLeft:"3%",
                                marginRight: "3%",
                            }}
                        >
                            {ordine.ds_note_suspend_admin}
                        </p>
                    </div>
                    ) : (
                        ""
                    )
                }
                </div>
                <div
                    style={{
                        marginLeft: "20%",
                        marginRight: "20%",
                        marginTop: "40px"
                    }}
                >
                   
                    {ordine.lavorazioni.map((el,index) => (
                        <LavorazioneCartCell key={el._id_lavorazione} lavorazione={el} isDetail={true}/>
                    ))}


                    {ordine.sconto && ordine.sconto > 0 ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                                alignItems: "end",
                                marginTop: "0px"
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "10px",
                                    color: "#BFBDC1",
                                    marginBottom: "0px",
                                    marginRight: "16px"
                                }}
                            >
                                SCONTO:
                            </p>
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "16px",
                                    color: "#28242D",
                                    marginBottom: "0px",
                                    
                                }}
                            >
                                € {formatPrice(ordine.sconto)}
                            </p>
                        </div>
                    ) : (
                        ""
                    )}

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            alignItems: "end",
                            marginTop: "0px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "10px",
                                color: "#BFBDC1",
                                marginBottom: "0px",
                                marginRight: "16px"
                            }}
                        >
                            TOTALE PRESCRIZIONE
                        </p>
                        <p
                            style={{
                                fontFamily: "Bold",
                                fontSize: "20px",
                                color: "#28242D",
                                marginBottom: "0px",
                                
                            }}
                        >
                            € {formatPrice(ordine.total)}
                        </p>
                    </div>
                    <div
                        style={{
                            marginTop:"28px",
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "60px"
                        }}
                    >
                        <label
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#BFBDC1",  
                                marginBottom: "8px",
                            }}
                        >
                            NOTE PRESCRIZIONE
                        </label>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#28242D",
                                textAlign:"left",
                                margin:"0px",
                                
                            }}
                        >
                            {ordine.ds_note}
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "28px",
                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            marginTop: "40px",
                            marginBottom: "32px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "20px",
                                color: "#28242D",
                                marginLeft: "20px",
                                marginBottom: "20px",
                                marginTop: "28px"
                            }}
                        >
                            Dati paziente
                        </p>
                        <div
                            style={{
                                marginLeft: "20px",
                                marginRight: "20px"
                            }}
                        >
                            <PersonalDataRow myKey="NOME COGNOME" value={ordine.paziente.nome_cognome}/>
                            <div 
                                style={{
                                    height: "1px",
                                    
                                    backgroundColor:"#E9EBE7",
                                    marginTop: "8px",
                                    
                                }}
                            />
                            <PersonalDataRow myKey="SESSO" value={ordine.paziente.sesso}/>
                            <div 
                                style={{
                                    height: "1px",
                                    
                                    backgroundColor:"#E9EBE7",
                                    marginTop: "8px",
                                    
                                }}
                            />
                            <PersonalDataRow myKey="ALLERGIE" value={ordine.paziente.allergie}/>
                            <div 
                                style={{
                                    height: "1px",
                                    
                                    backgroundColor:"#E9EBE7",
                                    marginTop: "8px",
                                    
                                }}
                            />
                            <PersonalDataRow myKey="FORMA DEL VISO" value={ordine.paziente.forma_viso}/>
                            <div 
                                style={{
                                    height: "1px",
                                    
                                    backgroundColor:"#E9EBE7",
                                    marginTop: "8px",
                                    
                                }}
                            />
                            <div
                                style={{
                                    marginTop:"28px",
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "32px"
                                }}
                            >
                                <label
                                    style={{
                                        fontFamily: "Medium",
                                        fontSize: "12px",
                                        color: "#BFBDC1",  
                                        marginBottom: "8px",
                                    }}
                                >
                                    NOTE PAZIENTE
                                </label>
                                <p
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize: "16px",
                                        color: "#28242D",
                                        textAlign:"left",
                                        margin:"0px",
                                        
                                    }}
                                >
                                    {ordine.paziente.ds_note}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            padding: "20px",
                            border: "2px solid #28242D",
                            borderRadius: "28px",
                            textAlign: "center",
                            marginTop: "60px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "20px",
                                color: "#28242D",
                                marginBottom: "8px",
                            }}
                        >
                            Attenzione!
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#6E6B77",
                                marginBottom: "8px",
                            }}
                        >
                            Ricordarsi di inviare STL o impronte dentali analogiche del paziente. Invia i file a&nbsp;
                            <span
                                style={{
                                    fontFamily: "Medium",
                                    color: "#94CC00"
                                }}
                            >
                                areadigitale@dentalmax.eu
                            </span>
                        </p>
                    </div>
                    <div
                        className="myButton"
                        onClick={handleWhatsapp}
                        style={{
                            marginLeft: "25%",
                            marginRight: "25%",
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#94CC00",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "52px",
                            borderRadius: "28px",
                            marginTop: "32px",
                            padding: "0px 24px 0px 20px",
                            cursor: "pointer"
                        }}
                    >
                        <img 
                            src={WhatsAppIcon}
                            alt=''
                            style={{
                                color: "#FFFFFF",
                                height: "24px",
                                width: "24px",
                                
                            }}
                        />
                        <label
                            style={{
                                fontFamily:"Bold",
                                fontSize:"16px",
                                color:"white",
                                cursor: "pointer",
                                marginLeft: "16px"
                            }}
                        >
                            INVIA FOTO PAZIENTE
                        </label>
                    </div>
                    <div
                        style={{
                            textAlign: "center",
                            marginTop:"60px",
                            marginBottom: "120px"
                        }}
                    >
                        <img
                            src={LogoFooter}
                            alt=''
                            style={{
                                height: "60px",
                                display: "inline-block",
                                
                            }}
                        />
                    </div>
                </div>
            </div>





        </div>
    )
}