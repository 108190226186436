import { useState, useEffect } from "react";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import { FooterStep } from "../../components/ordini/FooterStep";
import ExitOrderModal from "../../components/ordini/ExitOrderModal";
import { CustomButton } from "../../components/utility/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";


export function Step1View(props){

    const [nomeCognome, setNomeCognome] = useState("");
    const [allergie, setAllergie] = useState("");
    const [sexSelected, setSexSelected] = useState({
                                                        value: "",
                                                        label: "Seleziona..."
                                                    });
    const [visoSelected, setVisoSelected] = useState({
                                                        value: "",
                                                        label: "Seleziona..."
                                                    });
    const [notePaziente, setNotePaziente] = useState("");
    const [flag, setFlag] = useState(false);
    const [open, setOpen] = useState(false);
    const [isBackButton, setIsBackButton] = useState(true); 

    const navigate = useNavigate();
    
    useEffect(() => {

        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            
            navigate("/step1",{
                replace: false
            })
            
            
        }, false);

        
        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        } 
    }, []);

    function goNext(){
        if(nomeCognome !== "" && sexSelected !== ""){
            localStorage.setItem('cart', JSON.stringify({
                paziente: {
                    nome_cognome: nomeCognome,
                    sesso: sexSelected,
                    allergie: allergie,
                    forma_viso: visoSelected,
                    ds_note: notePaziente
                },
                cart:[],
                credential: props.user.credential
            }));
            navigate("/step2", {
                replace: false,
                state: {
                    paziente: {
                        nome_cognome: nomeCognome,
                        sesso: sexSelected,
                        allergie: allergie,
                        forma_viso: visoSelected,
                        ds_note: notePaziente
                    }
                }
            });
        }

    }

    function goBack(){
        navigate("/",{
            replace: false
        })
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    function handleNomeCognome(event){
        
        if(event && event.target && (event.target.value || event.target.value === "")){
            setNomeCognome(event.target.value);
            if(event.target.value !== "" && sexSelected !== ""){
                setFlag(true);
            } else {
                setFlag(false);
            }
        }
        
    }

    function handleAllergie(event){
        
        if(event && event.target && (event.target.value || event.target.value === "")){
            setAllergie(event.target.value);
        }
        
    }

    function getSexOptions(){
        return [
            {
                value: "",
                label: "Seleziona..."
            },
            {
                value: 'maschio',
                label: 'Maschio',
            },
            {
                value: 'femmina',
                label: 'Femmina',
            },
        ]
    }

    const visoOptions = getVisoOptions();

    function getVisoOptions(){
        return [
            {
                value: "",
                label: "Seleziona..."
            },
            {
                value: 'ovale',
                label: 'Ovale',
              },
              {
                value: 'rettangolare',
                label: 'Rettangolare',
              },
              {
                value: 'triangolare',
                label: 'Triangolare',
              },
        ]
    }

    const sexOptions = getSexOptions();

    const handleSexChange = event => {
        if(event && (event.value || event.value === "")){
            setSexSelected(event);
            if(nomeCognome !== "" && event.value !== ""){
                setFlag(true);
            } else {
                setFlag(false);
            }
        }
    }

    const handleVisoChange = event => {
        if(event && event){
            setVisoSelected(event);
        }
    }

    const handleTextNote = event => {
        
        if(event && event.target && event.target.value){
            setNotePaziente(event.target.value);
        }
    }

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                
                <div 
                    style={{
                        position:"fixed",
                        top:0,
                        zIndex:100,
                        width: "100%",
                        
                        backgroundColor: "#28242D",
                        borderBottomLeftRadius: "28px",
                        WebkitBorderBottomRightRadius: "28px",
                        boxShadow: "0 4px 2px -2px #28242D1A",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent:"end",
                            marginRight: "20%",
                            marginTop: "24px"
                        }}
                    >
                        <div
                            onClick={handleOpen}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                
                                alignItems: "center"
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize: "14px",
                                    color: "#BFBDC1",
                                    textDecoration: "underline",
                                    marginBottom: "0px",
                                    marginRight: "12px",
                                    marginTop: "0px",
                                    cursor: "pointer"
                                }}
                            >
                                Esci dalla prescrizione
                            </p>

                            <div
                            
                                style={{
                                    height: "36px",
                                    width: "36px",
                                    backgroundColor: "#6E6B77",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "18px",
                                    cursor: "pointer"
                                }}
                            >
                                <CloseIcon 
                                    style={{
                                        color: "#BFBDC1",
                                        height: "24px",
                                        width: "24px",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent:"left",
                            marginLeft: "20%",
                            marginRight: "20%"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "28px",
                                color: "#FFFFFF",
                                textAlign:"left",
                                marginBottom: "0px",
                                marginTop: "20px"
                            }}
                        >
                            Nuova prescrizione
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#BFBDC1",
                                textAlign:"left",
                                marginBottom: "28px",
                                marginTop: "4px"
                            }}
                        >
                            Compila i campi sottostanti per proseguire e creare una nuova prescrizione
                        </p>
                    </div>
                </div>
                
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    marginTop: "190px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "20px",
                        color: "#28242D",
                        
                        marginBottom: "0px",
                        marginTop: "32px"
                    }}
                >
                    Informazioni paziente
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        
                        marginBottom: "24px",
                        
                    }}
                >
                    I dati verranno inviati al fornitore per la preparazione della prescrizione.
                </p>
            </div>
            <div
                style={{
                    marginTop:"24px",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <label
                    style={{
                        fontFamily: "Medium",
                        fontSize: "12px",
                        color: "#BFBDC1",  
                        marginBottom: "0px",
                    }}
                >
                    NOME COGNOME*
                </label>
                
                <input 
                    type="text"
                    className="input"
                    value={nomeCognome}
                    placeholder="Inserisci..."
                    onChange={handleNomeCognome}
                />
                
                
            </div>
            <div
                style={{
                    marginTop:"12px"
                }}
            >
                <label
                    style={{
                        fontFamily: "Medium",
                        fontSize: "12px",
                        color: "#BFBDC1",  
                        marginBottom: "0px",
                    }}
                >
                    SESSO*
                </label>
                <Select 
                    defaultValue={sexSelected.value}
                    options={sexOptions} 
                    isSearchable={false}
                    placeholder="Seleziona..."
                    onChange={handleSexChange}
                    isClearable={false}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            width:"100%",
                            height: "48px",
                            padding: "0px 12px",
                            borderRadius: "12px",
                            border: "2px solid #E9EBE7",
                            backgroundColor: "#FFFFFF",
                            color: "#282c34",
                            fontFamily: "Regular",
                            fontSize: "16px",
                            marginTop: "8px"
                        })
                    }}
                    
                />
            </div>
            <div
                style={{
                    marginTop:"12px",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <label
                    style={{
                        fontFamily: "Medium",
                        fontSize: "12px",
                        color: "#BFBDC1",  
                        marginBottom: "0px",
                    }}
                >
                    ALLERGIE
                </label>
                
                <input 
                    type="text"
                    className="input"
                    value={allergie}
                    placeholder="Inserisci..."
                    onChange={handleAllergie}
                />
                
                
            </div>
            <div
                style={{
                    marginTop:"12px"
                }}
            >
                <label
                    style={{
                        fontFamily: "Medium",
                        fontSize: "12px",
                        color: "#BFBDC1",  
                        marginBottom: "0px",
                    }}
                >
                    FORMA DEL VISO
                </label>
                <Select 
                    defaultValue={visoSelected.value}
                    options={visoOptions} 
                    isSearchable={false}
                    placeholder="Seleziona..."
                    onChange={handleVisoChange}
                    isClearable={false}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            width:"100%",
                            height: "48px",
                            padding: "0px 12px",
                            borderRadius: "12px",
                            border: "2px solid #E9EBE7",
                            backgroundColor: "#FFFFFF",
                            color: "#282c34",
                            fontFamily: "Regular",
                            fontSize: "16px",
                            marginTop: "8px"
                        })
                    }}
                    
                />
            </div>
            <div
                style={{
                    marginTop:"40px",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "180px"
                }}
            >
                <label
                    style={{
                        fontFamily: "Medium",
                        fontSize: "12px",
                        color: "#BFBDC1",  
                        marginBottom: "0px",
                    }}
                >
                    NOTE PAZIENTE
                </label>
                <textarea 
                    placeholder="Inserire qui informazioni particolari o più dettagli dell'anagrafica del paziente..."
                    rows={4}
                    onChange={handleTextNote}
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#282c34",
                        borderRadius: "12px",
                        border: "2px solid #E9EBE7",
                        padding: "12px 24px",
                        marginTop: "8px"
                    }}
                />
                
            </div>
            <FooterStep step="1" text="PROSEGUI" flag={flag} handleClick={goNext}/>

            <ExitOrderModal isOpen={open} onClose={handleClose}>
                <>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"28px",
                            color: "#28242D",
                            textAlign: "center !important",
                            marginTop: "0px"
                        }}
                    >
                        Sei sicuro di voler uscire?
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#6E6B77",
                            textAlign:"center",
                            marginTop: "8px",
                            marginBottom:"0px"
                        }}
                    >
                        Se interrompi l'operazione perderai i dati che hai inserito e la prescrizione non verrà salvata.
                    </p>
                    
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: "52px",
                            width: "100%"
                        }}
                    >
                        <CustomButton 
                            titleTxt="ESCI DALLA PRESCRIZIONE"
                            myColor="#28242D"
                            handleAction={goBack}
                        />
                    </div>
                    <div
                        onClick={handleClose}
                        style={{
                            textAlign: "center",
                            marginTop: "30px",
                            width: "100%"
                        }}
                    >
                        <label
                            style={{
                                fontFamily: "Medium",
                                fontSize: "16px",
                                color: "#BFBDC1",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                        >
                            ANNULLA
                        </label>
                    </div>
                </>
            </ExitOrderModal>
        </div>
    )
}