import PasswordLogo from "@material-ui/icons/Lock";
import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { SERVICE_PATH } from "../../App";
import { useNavigate } from "react-router-dom";

export function RecuperaPasswordView(){

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    function handleMail(event){
        if(event && event.target && (event.target.value || event.target.value === "")){
            setEmail(event.target.value);
        }
    }

    function recuperaCredenziali(event){
        setIsLoading(true);
        axios.post(SERVICE_PATH + '/users/recovery', {
            email: email,
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if(response.data){
                
                setIsLoading(false);
                window.history.back();
               
            }
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
            
        })
    }

    return (
        <div
            style={{
                
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    width: "100%",
                    marginTop: "24px"
                }}
            >
                <div
                    onClick={() => navigate("/",{
                        replace: false
                    })}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        
                        alignItems: "center"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize: "14px",
                            color: "#6E6B77",
                            textDecoration: "underline",
                            marginBottom: "0px",
                            marginRight: "12px",
                            marginTop: "0px",
                            cursor: "pointer"
                        }}
                    >
                        Torna alla login
                    </p>
                    <div
                        
                        style={{
                            height: "36px",
                            width: "36px",
                            backgroundColor: "#E9EBE7",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "18px",
                            cursor: "pointer"
                        }}
                    >
                        <CloseIcon 
                            style={{
                                color: "#6E6B77",
                                height: "24px",
                                width: "24px",
                            }}
                        />
                    </div>
                </div>
            </div>
            <PasswordLogo 
                style={{
                    color: "#94CC00",
                    height: "40px",
                    width: "40px",
                    marginTop: "80px",
                }}
            />
            
            <p      
                style={{
                    fontFamily:"Medium",
                    fontSize: "28px",
                    color: "#28242D",
                    marginTop: "12px",
                    marginBottom: "0px"
                }}>
                Recupera credenziali di accesso
            </p>
            <p      
                style={{
                    fontFamily:"Regular",
                    fontSize: "16px",
                    color: "#6E6B77",
                    marginTop: "18px",
                    textAlign: "center"
                }}>
                Inserisci un'indirizzo email valido, ti invieremo un link per il reset della tua password.
            </p>

            <input 
                type="text"
                className="input"
                value={email}
                placeholder="Inserisci email"
                onChange={handleMail}
                style={{
                    width: "100%"
                }}
            />
            
            <div
                className="myButton"
                onClick={recuperaCredenziali}
                style={{
                    width: "295px",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#28242D",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "52px",
                    borderRadius: "28px",
                    marginTop: "32px",
                    padding: "0px 24px 0px 20px",
                    cursor: "pointer"
                }}
            >
                
                <label
                    style={{
                        fontFamily:"Bold",
                        fontSize:"16px",
                        color:"white",
                        cursor: "pointer",
                        marginLeft: "16px"
                    }}
                >
                    PROCEDI
                </label>
            </div>
            <div
                onClick={() => window.history.back()}
                style={{
                    textAlign: "center",
                    marginTop: "32px",
                    width: "100%"
                }}
            >
                <label
                    style={{
                        fontFamily: "Medium",
                        fontSize: "16px",
                        color: "#BFBDC1",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                >
                    ANNULLA
                </label>
            </div>
        </div>
    )
}