
export function StatusChip(props){

    function setStatus(){
        props.setStatus(props.status);
    }

    return (
        <div
            onClick={setStatus}
            style={{
                display: "flex",
                flexDirection: "row",
                height: "32px",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 12px",
                backgroundColor: `${props.isSelected ? props.status.color : "#FFFFFF"}`,
                cursor: `${props.isDetail ? "auto" : "pointer"}`,
                borderRadius: "16px",
                marginRight: `${props.isDetail ? "0px" : "8px"}`,
                whiteSpace: "nowrap"
            }}
        >
            <img
                src={props.status.icon}
                alt=''
                style={{
                    height: "16px",
                    width: "16px",
                    display: "inline-block",
                    marginRight:"4px",
                    
                    filter: `${props.isSelected ? "invert(100%) sepia(9%) saturate(7500%) hue-rotate(264deg) brightness(112%) contrast(113%)" : "invert(42%) sepia(0%) saturate(5366%) hue-rotate(296deg) brightness(102%) contrast(76%)"}`
                }}
            />
            <label
                style={{
                    fontFamily: "Medium",
                    fontSize: "12px",
                    color: `${props.isSelected ? "#FFFFFF" : "#6E6B77"}`,
                    cursor: `${props.isDetail ? "auto" : "pointer"}`
                }}
            >
                {props.status.label}
            </label>

        </div>
    )
}