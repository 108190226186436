import "../../App.css";
import { useState } from "react";
import Logo from "../../images/loghi_login.png";
import EmailLogo from "@material-ui/icons/Email";
import PasswordLogo from "@material-ui/icons/Lock";
import TextFieldWithIcon from "../../components/TextFieldWithIcon";
import { CustomButton } from "../../components/utility/CustomButton";
import PhoneLogo from "@material-ui/icons/Phone";
import IphoneLogo from "@material-ui/icons/PhoneIphone";
import PrivacyModal from "../../components/privacy/PrivacyModal";
import axios from "axios";
import LoaderCustom from "../../components/utility/LoaderCustom";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { SERVICE_PATH } from "../../App";

export function LoginView(props){
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [privacyAccepted, setPrivacyAccepted] = useState(false);
    const [show, setShow] = useState(false);
    const [user, setUser] = useState(null);
    const [requestError, setRequestError] = useState(null);

    const navigate = useNavigate();

    function login(){
        if(privacyAccepted){
            
            setIsLoading(true);
            axios.post(SERVICE_PATH + '/users/signin', {
                email: email,
                password: password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if(response.data){
                    
                    setIsLoading(false);
                    setUser(response.data.user);
                    localStorage.setItem('user', JSON.stringify({email:email, password: password, token: response.data.token})); 
                    props.getUser(response.data.user);
                    setOpen(false);
                    navigate("/",{
                        replace: true
                    })
                }
            }).catch((error) => {
                console.error(error);
                setRequestError("Email o password non corrette. Ricorda di scrivere la password e non copiarla e incollarla perchè potrebbe non essere riconosciuta. Se il problema sussiste contattare lo staff dentalMax.");
                setIsLoading(false);
                setOpen(false);
            })
        }
    }

    function recuperaPsw(){
        navigate("/recoveryPsw", {
            replace: false
        })
    }

    function contattaci(){
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSeg3krKN1WUru8hQ8pQOY8FeDAQ31LKn-46QN_rpRNSwZGwaA/viewform");
    }

    function handleEmail(){
        window.open("mailto:areadigitale@dentalmax.eu");
    }

    function handlePhone(){
        window.open('tel:0114222925');
    }

    function handleCellular(){
        window.open('tel:3892078710');
    }

    const handleClose = () => {
        if(privacyAccepted){
            setOpen(false);
        } else {
            setShow(true);
        }
        
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleCheckPrivacy = () => {
        let flag = !privacyAccepted;
        setPrivacyAccepted(flag);
    }

    return (
        <div
            style={{
                width:"100%",
                textAlign: "center",
                
            }}
        >
            <div
                style={{
                    marginTop:"24px",
                    display: "flex",
                    justifyContent:"center",
                    alignItems: "center",
                    flexDirection: "column",
                    
                }}
            >
                <img
                    src={Logo}
                    alt=''
                    style={{
                        height: "110px",
                        display: "inline-block",
                        marginTop:"40px"
                    }}
                />
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "28px",
                        color: "#28242D",
                        textAlign:"center",
                        marginBottom: "0px",
                        marginTop: "60px"
                    }}
                >
                    Accedi al tuo profilo
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        textAlign:"center",
                        marginTop: "8px",
                        marginBottom:"0px"
                    }}
                >
                    Effettua il login per accedere al servizio
                </p>
                <div
                    style={{
                        width: "70%",
                        marginTop: "32px"
                    }}
                >
                    <TextFieldWithIcon
                        id="input"
                        type="text"
                        lightbackground
                        placeholder="Email"
                        value={email}
                        icon={<EmailLogo style={{color:"#BFBDC1", marginRight:"12px"}}/>}
                        onChange={(id, value) => {
                            
                            setEmail(value);
                        }}
                        style={{
                            marginLeft: "40px",
                            marginRight: "40px",
                        }}
                    />
                </div>
                <div
                    style={{
                        width: "70%",
                        marginTop: "12px"
                    }}
                >
                    <TextFieldWithIcon
                        id="input"
                        type="text"
                        lightbackground
                        placeholder="Password"
                        value={password}
                        isPassword={true}
                        icon={<PasswordLogo style={{color:"#BFBDC1", marginRight:"12px"}}/>}
                        onChange={(id, value) => {
                            
                            setPassword(value);
                        }}
                        style={{
                            marginLeft: "40px",
                            marginRight: "40px",
                        }}
                    />
                </div>
                {
                    requestError ? (
                        <div
                            style={{
                                width:"100%",
                                textAlign: "center",
                                marginTop: "23px",
                                
                            }}
                        >
                            <label
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "12px", 
                                    color: "#AD001A"
                                }}
                            >
                                {requestError}
                            </label>
                        </div>
                    ) : (
                        ""
                    )
                }
                <div
                    style={{
                        width:"100%",
                        textAlign: "center",
                        marginTop: "23px",
                        
                    }}
                >
                    <label
                        style={{
                            fontFamily: "Medium",
                            fontSize: "16px", 
                            color: "#28242D"
                        }}
                    >
                        <input 
                            type="checkbox"
                            style={{
                                accentColor: "black",
                                color: "white",
                                width: "16px",
                                height: "16px",
                                marginRight: "12px"
                            }}
                        />
                        Ricorda le mie credenziali
                    </label>
                </div>
                <div
                    style={{
                        textAlign: "center",
                        marginLeft: "40px",
                        marginRight: "40px",
                        marginTop: "23px",
                        width: "50%"
                    }}
                >
                    <CustomButton 
                        titleTxt="ACCEDI"
                        myColor="#94CC00"
                        handleAction={handleOpen}
                    />
                </div>
                <div
                    onClick={recuperaPsw}
                    style={{
                        textAlign: "center",
                        marginLeft: "40px",
                        marginRight: "40px",
                        marginTop: "20px",
                        cursor: "pointer",
                        
                    }}
                >
                    <label
                        style={{
                            fontFamily:"Medium",
                            fontSize: "12px",
                            color: "#BFBDC1",
                            textDecoration: "underline",
                            cursor: "pointer"
                        }}
                    >
                        Ho dimenticato la password
                    </label>
                </div>
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "28px",
                        color: "#28242D",
                        textAlign:"center",
                        marginBottom: "0px",
                        marginTop: "60px"
                    }}
                >
                    Non sei registrato?
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        textAlign:"center",
                        marginTop: "8px",
                        marginBottom:"0px"
                    }}
                >
                    Crea l'account dello studio per accedere a tutti i nostri servizi.
                </p>
                <div
                    style={{
                        textAlign: "center",
                        marginLeft: "40px",
                        marginRight: "40px",
                        marginTop: "23px",
                        width: "50%"
                    }}
                >
                    <CustomButton 
                        titleTxt="CONTATTACI"
                        myColor="#28242D"
                        handleAction={contattaci}
                    />
                </div>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        textAlign:"center",
                        margin: "32px 40px",
                        
                    }}
                >
                    Siamo qui per aiutarti a velocizzare il tuo lavoro di comunicazione con il team del laboratorio odontotecnico. Se hai dubbi e necessità urgenti chiamaci, ti daremo il supporto necessario.
                </p>

                <div
                    onClick={handlePhone}
                    style={{
                        width:"100%",
                        textAlign: "center",
                        cursor: "pointer"
                    }}
                >
                    <PhoneLogo 
                        style={{
                            color: "#94CC00",
                            height: "20px",
                            width: "20px",
                            marginRight: "12px"
                        }}
                    />
                    <label
                        style={{
                            color: "#94CC00",
                            fontFamily: "Medium",
                            fontSize:"18px",
                            textDecoration: "underline",
                            cursor: "pointer"
                        }}
                    >
                        011 4222925
                    </label>

                </div>
                <div
                    onClick={handleCellular}
                    style={{
                        width:"100%",
                        textAlign: "center",
                        marginTop: "20px",
                        cursor: "pointer"
                    }}
                >
                    <IphoneLogo 
                        style={{
                            color: "#94CC00",
                            height: "20px",
                            width: "20px",
                            marginRight: "12px",
                            
                        }}
                    />
                    <label
                        style={{
                            color: "#94CC00",
                            fontFamily: "Medium",
                            fontSize:"18px",
                            textDecoration: "underline",
                            cursor: "pointer"
                        }}
                    >
                        389 2078710
                    </label>

                </div>
                <div
                    onClick={handleEmail}
                    style={{
                        width:"100%",
                        textAlign: "center",
                        marginTop: "20px",
                        marginBottom: "120px",
                        verticalAlign:"center",
                        cursor: "pointer"
                    }}
                >
                    <EmailLogo 
                        style={{
                            color: "#94CC00",
                            height: "20px",
                            width: "20px",
                            marginRight: "12px"
                        }}
                    />
                    <label
                        style={{
                            color: "#94CC00",
                            fontFamily: "Medium",
                            fontSize:"18px",
                            textDecoration: "underline",
                            cursor: "pointer"
                        }}
                    >
                        areadigitale@dentalmax.eu
                    </label>

                </div>
                <PrivacyModal isOpen={open} onClose={handleClose}>
                    <>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"28px",
                                color: "#28242D",
                                textAlign: "center !important",
                                marginTop: "0px"
                            }}
                        >
                            La tua privacy è importante per noi
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#6E6B77",
                                textAlign:"center",
                                marginTop: "8px",
                                marginBottom:"0px"
                            }}
                        >
                            Per poter proseguire e utilizzare il servizio, è necessario accettare l'informativa privacy.
                        </p>
                        <div
                            style={{
                                width:"100%",
                                textAlign: "left",
                                marginTop: "52px",
                                marginLeft: "40px"
                            }}
                        >
                            <label
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "16px", 
                                    color: "#28242D"
                                }}
                            >
                                <input 
                                    type="checkbox"
                                    checked={privacyAccepted}
                                    onChange={handleCheckPrivacy}
                                    style={{
                                        accentColor: "black",
                                        color: "white",
                                        width: "16px",
                                        height: "16px",
                                        marginRight: "12px",
                                        border: "2px solid red"
                                    }}
                                />
                                Ho letto e compreso l'
                                <a 
                                    href="https://dentalmax.eu/privacy-policy/"
                                    target="_blank"
                                    style={{
                                        color:"#94CC00",
                                        textDecoration: "underline"
                                    }} rel="noreferrer"
                                >
                                    informativa privacy
                                </a>
                            </label>
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                marginTop: "52px",
                                width: "100%"
                            }}
                        >
                            <CustomButton 
                                titleTxt="SALVA E CHIUDI"
                                isPrivacy={true}
                                flag={privacyAccepted}
                                myColor={privacyAccepted ? "#28242D" : "#E9EBE7"}
                                handleAction={login}
                            />
                        </div>
                    </>
                </PrivacyModal>
            </div>

            {isLoading ? (
                <div
                    style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        marginTop: "-50px",
                        marginLeft: "-50px",
                        width: "100%",
                        height: "100%",
                        zIndex: "1000"
                    }}
                >
                    <LoaderCustom
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%"
                        }}
                    />
                </div>
            ) : (
                ""
            )}
            
        </div>
    )
}