import LeftArrow from "@material-ui/icons/ArrowBack";
import { PersonalDataRow } from "../../components/profile/PersonalDataRow";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";

export function PersonalDataView(props) {

    const navigate = useNavigate();

    useEffect(() => {

        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.
        
            // Stay on the current page.
            navigate("/profile/personalData",{
                replace: false
            })
            
        }, false);

        
        const loggedInUser = localStorage.getItem("user");
        if (!loggedInUser) {
            navigate("/signin",{
                replace: true
            })
        } 
    }, []);

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                <div 
                    style={{
                        position:"fixed",
                        top:0,
                        
                        width: "100%",
                        height: "80px",
                        backgroundColor: "white",
                        borderBottomLeftRadius: "28px",
                        WebkitBorderBottomRightRadius: "28px",
                        boxShadow: "0 4px 2px -2px #28242D1A",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <div
                        onClick={() => navigate("/profile",{
                            replace: false
                        })}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "20%",
                            alignItems: "center"
                        }}
                    >
                        
                        <LeftArrow 
                            
                            style={{
                                color: "#6E6B77",
                                height: "28px",
                                width: "28px",
                                marginLeft: "20%",
                                cursor: "pointer"
                            }}
                        />
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "14px",
                                color: "#6E6B77",
                                textDecoration: "underline",
                                marginBottom: "0px",
                                marginLeft: "12px",
                                marginTop: "0px",
                                cursor: "pointer"
                            }}
                        >
                            Indietro
                        </p>
                    </div>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize: "12px",
                            color: "#BFBDC1",
                            textAlign:"right",
                            marginBottom:"0px",
                            marginRight: "20%"
                        }}
                    >
                        DATI PERSONALI
                    </p>
                </div>
               
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    marginTop: "120px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "20px",
                        color: "#28242D",
                        
                        marginBottom: "0px",
                        marginTop: "32px"
                    }}
                >
                    Riepilogo dati utente
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        marginBottom: "24px",
                        marginTop: "12px"
                    }}
                >
                    Dati dell'account di accesso all'applicazione
                </p>
            </div>
            <PersonalDataRow myKey="NOME COGNOME" value={props.user.credential.fullname}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="N. ISCRIZIONE ALBO" value={props.user.credential.iscrizione_albo}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="EMAIL USER" value={props.user.credential.email}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    marginTop: "50px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "20px",
                        color: "#28242D",
                        
                        marginBottom: "0px",
                        marginTop: "32px"
                    }}
                >
                    Riepilogo dati aziendali
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#6E6B77",
                        
                        marginBottom: "24px",
                        marginTop: "12px"
                    }}
                >
                    Dati aziendali presenti a sistema
                </p>
            </div>
            <PersonalDataRow myKey="RAGIONE SOCIALE" value={props.user.ragione_sociale}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="PARTITA IVA" value={props.user.piva}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />

            <div style={{height:"30px"}}/>

            <PersonalDataRow myKey="INDIRIZZO" value={props.user.indirizzo}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="CITTÀ" value={props.user.citta}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="CODICE POSTALE" value={props.user.cap}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="PROVINCIA" value={props.user.provincia}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="INDIRIZZO CONSEGNA" value={props.user.indirizzo_consegna}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />

            <div style={{height:"30px"}}/>
            
            <PersonalDataRow myKey="EMAIL" value={props.user.email}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="EMAIL PRESCRIZIONI" value={props.user.email_ordine}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="TELEFONO FISSO" value={props.user.telefono_fisso}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    
                }}
            />
            <PersonalDataRow myKey="CELLULARE" value={props.user.cellulare}/>
            <div 
                style={{
                    height: "1px",
                    
                    backgroundColor:"#E9EBE7",
                    marginTop: "8px",
                    marginBottom: "120px"
                    
                }}
            />

        </div>
    )
}