import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

const Container = styled(Form.Group)`
	display: flex;
	justify-content: normal;
	align-items: center;
    height: 40px;
	background-color: white;
	border: 2px solid #E9EBE7;
	border-radius: 8px;

	padding: 2px;

	cursor: text;
`;

const Input = styled(Form.Control)`
	background: white;
	border: none;
    height: 40px;
    width: 100%;
    font-family: Regular;
    font-size: 16px;
    color: #28242D;
	padding: 0px 8px;
	&:focus {
		box-shadow: none;
        border:none;
	}
`;

const Icon = styled.div`
	padding-left: 8px;

	color: ${(props) => (props.iconColor ? props.iconColor : "#BFBDC1")};
`;



function TextFieldSearch(props) {

	const inputRef = useRef();
	const [searching, setSearching] = useState(false);


	return (
		<Container className={props.className} style={{ ...props.style }}>
            <Icon
				onClick={() => {
					if (searching) {
						props.onChange(props.id, "");
						setSearching(false);
					} 
				}}
			>
                
				{searching ? <CloseIcon className="cursor-pointer" /> : <SearchIcon />}
			</Icon>
			<Input 
				type={props.isPassword ? "password" : "text"}
				placeholder={props.placeholder}
				value={props.value}
				onChange={(e) => {
					props.onChange(props.id, e.target.value);
					setSearching(e !== "");
				}}
			/>
			
		</Container>
	);
}

TextFieldSearch.defaultProps = {
	placeholder: "Cerca prescrizioni...",
	value: "",
	onChange: (e) => {},
};

export default TextFieldSearch;